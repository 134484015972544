export const appointmentConstants = {
    // get all APPOINTMENT
    GET_ALL_APPOINTMENT_REQUEST: "GET_ALL_APPOINTMENT_REQUEST",
    GET_ALL_APPOINTMENT_SUCCESS: "GET_ALL_APPOINTMENT_SUCCESS",
    GET_ALL_APPOINTMENT_FAILURE: "GET_ALL_APPOINTMENT_FAILURE",
  
    // add APPOINTMENT
    ADD_APPOINTMENT_REQUEST: "ADD_APPOINTMENT_REQUEST",
    ADD_APPOINTMENT_SUCCESS: "ADD_APPOINTMENT_SUCCESS",
    ADD_APPOINTMENT_FAILURE: "ADD_APPOINTMENT_FAILURE",
    RESET_APPOINTMENT_ADDED: "RESET_APPOINTMENT_ADDED",
  
    // edit APPOINTMENT
    EDIT_APPOINTMENT_REQUEST: "EDIT_APPOINTMENT_REQUEST",
    EDIT_APPOINTMENT_SUCCESS: "EDIT_APPOINTMENT_SUCCESS",
    EDIT_APPOINTMENT_FAILURE: "EDIT_APPOINTMENT_FAILURE",
    RESET_APPOINTMENT_UPDATED : "RESET_APPOINTMENT_UPDATED",
  
    // delete APPOINTMENT
    DELETE_APPOINTMENT_REQUEST: "DELETE_APPOINTMENT_REQUEST",
    DELETE_APPOINTMENT_SUCCESS: "DELETE_APPOINTMENT_SUCCESS",
    DELETE_APPOINTMENT_FAILURE: "DELETE_APPOINTMENT_FAILURE",
  };