import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Divider,
  Box,
  Paper,
  Fade,
  Zoom,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  List,
  ListItem,
  TablePagination,
  Tooltip
} from "@mui/material";
import {
  Close,
  Call,
  Email,
  Feedback,
  AccountBalanceWallet,
  Dashboard,
} from "@mui/icons-material";
import CustomerDashboardContainer from "./CustomerDashboard";
import { User2Icon } from "lucide-react";
import { CrmService } from "../../services";

const CallDetailsPopup = ({ open, onClose, customerInfo, customerId }) => {
  const [selectedTab, setSelectedTab] = useState("dashboard");
  const [callLogs, setCallLogs] = useState([]);
  const [callCount, setCallCount] = useState(0);
  const [emailCount, setEmailCount] = useState(0);
  const [feedCount, setFeedCount] = useState(0);
  const [emailLogs, setEmailLogs] = useState([]);
  const [feedbackLogs, setFeedbackLogs] = useState([]);

  // Mock data for engagements

  useEffect(() => {
    (async () => {
      const callLogs = await CrmService.getAllCallsLogs(customerId);
      setCallLogs(callLogs.data);
      setCallCount(callLogs.data.call_logs.length);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const emailLogs = await CrmService.getEmailsHistory(customerId);
      setEmailLogs(emailLogs?.data?.email_logs);
      setEmailCount(emailLogs?.data?.email_logs.length);
    })();
  }, []);


  useEffect(() => {
    (async () => {
      const feedbackLogs = await CrmService.getFeedbackResponses(customerId);
      setFeedbackLogs(feedbackLogs?.data?.event_data);
      setFeedCount(feedbackLogs?.data?.event_data.length)
    })();
  }, []);


  const engagementData = {
    calls: callLogs.call_logs,

    emails: emailLogs?.map((email) => ({
      Subject: email.subject,
      text: email.body.replace(/<\/?[^>]+(>|$)/g, ""), // Strips HTML tags from the body
      date: new Date(email.created_at).toLocaleDateString(),
      "No of Email seen": `${email.id} Nos`,
    })),

    feedbacks: feedbackLogs?.map((feedback) => ({
      survey_name: feedback?.content,
      survey_id: feedback?.survey_id,
      Engagement: feedback.customer_engagement,
      Responsiveness: feedback.customer_responsiveness,
      Responses_count: feedback.positive_responses_count,
    })),


    // feedbacks: [
    //   {
    //     text: "Survey feedback",
    //     date: "2023-08-25",
    //     sentiment: "Positive",
    //     comments: "Great service!",
    //   },
    //   {
    //     text: "Form submission",
    //     date: "2023-09-10",
    //     sentiment: "Neutral",
    //     comments: "Average experience.",
    //   },
    // ],
  };

  const paymentHistory = [
    {
      text: "Payment for Invoice #1234",
      date: "2023-08-01",
      amount: "$100",
      status: "Completed",
    },
    {
      text: "Payment for Invoice #5678",
      date: "2023-09-01",
      amount: "$150",
      status: "Completed",
    },
  ];

  const menuItems = [
    { label: "Dashboard", icon: <Dashboard />, key: "dashboard" },
    { label: "Calls", icon: <Call />, key: "calls" },
    { label: "Emails", icon: <Email />, key: "emails" },
    { label: "Feedback", icon: <Feedback />, key: "feedbacks" },
    { label: "Payments", icon: <AccountBalanceWallet />, key: "payments" },
  ];

  console.log("selectedTab", selectedTab);
  const handleGridClick = (tab) => {
    setSelectedTab(tab); // Update selected tab dynamically
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      TransitionComponent={Zoom}
      transitionDuration={300}
      PaperProps={{
        sx: {
          borderRadius: 4,
          overflow: "hidden",
          boxShadow: 8,
          height: "90vh",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <DialogTitle
        sx={{
          background: "#d1ecff",
          color: "white",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 2,
          zIndex: 1200,
        }}
      >
        <Box display="flex" gap={1} alignItems="center">
          <User2Icon color="black" />
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Customer Engagement Details
          </Typography>
        </Box>
        <IconButton edge="end" onClick={onClose} sx={{ color: "black" }}>
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{
          p: 0,
          display: "flex",
          flexGrow: 1,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            minWidth: "240px",
            bgcolor: "#f5f5f5",
            borderRight: "1px solid #e0e0e0",
            overflow: "auto",
            position: "sticky",
            top: 0,
            height: "100%",
            zIndex: 1100,
          }}
        >
          <List sx={{ p: 2 }}>
            {menuItems.map((item) => (
              <ListItem
                button
                key={item.key}
                selected={selectedTab === item.key}
                onClick={() => setSelectedTab(item.key)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 1,
                  bgcolor: selectedTab === item.key ? "#d1ecff" : "transparent",
                  borderRadius: 2,
                  "&:hover": {
                    bgcolor: selectedTab === item.key ? "#d1ecff" : "#e3e3e3",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mr: 1,
                    color:
                      selectedTab === item.key ? "#1976d2" : "text.secondary",
                  }}
                >
                  {item.icon}
                </Box>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: selectedTab === item.key ? 600 : 500,
                    color:
                      selectedTab === item.key ? "#1976d2" : "text.primary",
                  }}
                >
                  {item.label}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            p: 3,
            overflow: "auto", // Enable scroll for content
            height: "100%",
          }}
        >
          <Fade in={open} timeout={500}>
            <Box>
              {selectedTab === "dashboard" && <CustomerDashboardContainer 
              customerInfo={customerInfo} 
              emailCount={emailCount}
              callCount={callCount}
              feedCount={feedCount}
              onGridClick={handleGridClick}
              />}
              {selectedTab === "calls" && (
                <ContentSection title="Call History">
                  <StyledTable rows={engagementData.calls} />
                </ContentSection>
              )}
              {selectedTab === "emails" && (
                <ContentSection title="Email History">
                  <StyledTable rows={engagementData.emails} />
                </ContentSection>
              )}
              {selectedTab === "feedbacks" && (
                <ContentSection title="Feedback Responses">
                  <StyledTable rows={engagementData.feedbacks} />
                </ContentSection>
              )}
              {selectedTab === "payments" && (
                <ContentSection title="Payment History">
                  <StyledTable rows={paymentHistory} />
                </ContentSection>
              )}
            </Box>
          </Fade>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

// ContentSection and StyledTable components remain the same
const ContentSection = ({ title, children }) => (
  <Box>
    <Typography variant="h6" sx={{ fontWeight: 600, mb: 2 }}>
      {title}
    </Typography>
    <Divider sx={{ mb: 2 }} />
    {children}
  </Box>
);

const StyledTable = ({ rows = [] }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const columns = rows.length > 0 ? Object.keys(rows[0]) : [];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedRows = rows.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Paper sx={{ borderRadius: 2, overflow: "hidden" }}>
      <TableContainer
        sx={{
          maxHeight: 400, // Set a fixed height for the table container
          overflowY: "auto", // Enable vertical scrolling for the table content
        }}
      >
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              {columns.map((col) => (
                <TableCell
                  key={col}
                  sx={{ fontWeight: "bold", borderBottom: "1px solid #ddd" }}
                >
                  {col.charAt(0).toUpperCase() + col.slice(1)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedRows.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  align="center"
                  sx={{ color: "text.secondary" }}
                >
                  No data found
                </TableCell>
              </TableRow>
            ) : (
              paginatedRows?.map((row, index) => (
                <TableRow key={index}>
                  {columns.map((col) => (
                    <TableCell key={col} sx={{ borderBottom: "1px solid #eee" }}>
                      {col === "text" && row[col] ? (
                        <Tooltip title={row[col]} arrow>
                          <span>
                            {row[col].length > 16
                              ? `${row[col].slice(0, 16)}...`
                              : row[col]}
                          </span>
                        </Tooltip>
                      ) : (
                        // Ensure fallback value is always a valid ReactNode
                        row[col] !== undefined && row[col] !== null ? (
                          row[col]
                        ) : (
                          "N/A"
                        )
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              )) 
            )}
          </TableBody>

        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15]} // Options for rows per page
        component="div"
        count={rows.length} // Total number of rows
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default CallDetailsPopup;
