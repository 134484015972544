import React, { useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import api from "../../services/api";
import { getUser, arrObjSorting, checkEmail, checkPhone } from "../Common/Utils";
import Backdrop from "../Common/Backdrop";
import Propmt from "../Common/ConfirmationPrompt";
import './Settings.scss';

const UpdateAlert = async (data) => {
    const result = await api.put(`/updateUser`, data);
    return result;
};

const ApolloInfo = () => {
    const [apiTypeList, setApiTypeList] = useState([
        { value: "type1", label: "Enrichment API", apis: ["People Enrichment", "Bulk People Enrichment", "Organization Enrichment","Bulk Organization Enrichment"] },
        { value: "type2", label: "Search API", apis: ["People Search", "Organization Search", "Organization Job Postings"] },
        { value: "type3", label: "Contacts API", apis: ["Create a Contact", "Update a Contact", "Search for Contacts","Update Contact Stage for Multiple Contacts", "Update Contact Owner for Multiple Contacts", "List Contact Stages"] },
        { value: "type4", label: "Accounts API", apis: ["Create a Account", "Update a Account", "Search for Accounts", "Update Account Stage for Multiple Accounts", "Update Account Owner for Multiple Accounts", "List Account Stages"] },
        { value: "type5", label: "Miscellaneous API", apis: ["Get a List of Users", "Get a List of Email Accounts", "Get a List of All Lists and Tags",] },
    ]);

    const [apiNameList, setApiNameList] = useState([]);
    const [msg, setMsg] = useState({});
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [err, setErr] = useState("");

    useEffect(() => {
        const usr = getUser();
        if (usr) {
            if (usr?.api_type?.length) {
                updateApiNames(usr.api_type);
            }
            setData({
                //alert_email: usr.alert_email,
                api_endpoint: usr.api_endpoint,
                api_type: usr.api_type,
                api_name: usr.api_name,
            });
        }
    }, []);

    const updateApiNames = (selectedApiType) => {
        const selectedType = apiTypeList.find(type => type.value === selectedApiType);
        if (selectedType) {
            setApiNameList(selectedType.apis);
            setData(data => ({ ...data, api_name: "" }));
        }
    };

    const onChange = (e) => {
        const { name, value } = e.target || {};
        setData(data => ({ ...data, [name]: value }));

        if (name === "api_type") {
            updateApiNames(value);
        }
    };

    const onUpdate = () => {
        let alert_email = "", api_endpoint = "", api_type = "", api_name = "";

        if (data?.api_endpoint?.length) {
            if (!data?.api_type?.length || !data?.api_name?.length) {
                setErr("API Type and API Name are required");
                return;
            }
            const validPhone = checkPhone(data.api_endpoint);
            if (!validPhone) {
                setErr("Enter a valid telephone number");
                return;
            } else {
                api_endpoint = data.api_endpoint;
                api_type = data.api_type;
                api_name = data.api_name;
            }
        }
        // if (data?.alert_email?.length) {
        //     const validEmail = checkEmail(data.alert_email);
        //     if (!validEmail) {
        //         setErr("Enter a valid email");
        //         return;
        //     } else {
        //         alert_email = data.alert_email;
        //     }
        // }
        if (api_endpoint === "") {
            setErr("Enter email or telephone to proceed");
        } else {
            setErr("");
            setLoading(true);
            UpdateAlert({api_endpoint, api_type, api_name }).then((res) => {
                if (res && res.status === 200 && res.data.data) {
                    let user = localStorage.getItem("user");
                    user = user && JSON.parse(user);
                    localStorage.setItem("user", JSON.stringify({ ...user, ...res.data.data }));
                }
                setLoading(false);
            }, (error) => {
                const e = error?.response?.data?.message?.toString() || "";
                setErr(e?.length ? e : "Something went wrong");
                setLoading(false);
            });
        }
    };

    return (
        <div className='profile__form'>

            <div className="form-group">
                <div className="form-select-group">
                <label htmlFor="api_type" className="form-label">API Type<p className="star">*</p></label>
                    <FormControl sx={{ minWidth: 120 }} size="small">
                        <Select
                            name="api_type"
                            value={data.api_type || ""}
                            onChange={onChange}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem key="Empty" value="" disabled>API Type</MenuItem>
                            {apiTypeList.map(apiType => (
                                <MenuItem key={apiType.value} value={apiType.value}>{apiType.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    </div>
                    </div>
                    
                    <div className="form-group">
                    <div className="form-select-group">
                    <label htmlFor="api_name" className="form-label">API Name<p className="star">*</p></label>
                    <FormControl sx={{ minWidth: 120 }} size="small">
                        <Select
                            name="api_name"
                            value={data.api_name || ""}
                            onChange={onChange}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem key="Empty" value="" disabled>API Name</MenuItem>
                            {apiNameList.map((apiName, index) => (
                                <MenuItem key={index} value={apiName}>{apiName}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </div>

            <div className="form-group">
                <label htmlFor="api_endpoint" className="form-label">API Endpoint<p className="star">*</p></label>
                <div className="input-group">
                    <input type="api_endpoint" id="api_endpoint" className="form-input" name="api_endpoint"
                        placeholder="Enter API Endpoint" value={data.api_endpoint || ""} onChange={onChange} />
                    <p className="input-description">Set up apollo api endpoint for the data integration in voc.</p>
                </div>
            </div>
            

            {err.length ? <span style={{ color: 'red' }}>{err}</span> : null}
            <button className="form-submit" onClick={onUpdate}>Update</button>
            <Backdrop show={loading} />
            <Propmt show={msg.show} handler={() => setMsg({})} message={msg.text} singleBtn={true} btnLabel="OK" />
        </div>
    );
};

export default ApolloInfo;
