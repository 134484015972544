import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Button from "../../common/controls/Button";
import Checkbox from "../../common/controls/inputs/Checkbox";
import Input from "../../common/controls/inputs/Input";
import { CommonWrapper, Wrapper } from "../Common/CommonWrapper";

import MicrosoftAcc from "../../components/Email_Integration/Outlook";
import GoogleAcc from "../../components/Email_Integration/Gmail";

import { checkEmail } from "../Common/Utils";
import { INPUT_ERRORS } from "../Common/Constants";
import { history } from "../../helpers";
import { userActions } from "../../actions";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";

import "./Signup.css";

const SignUpContent = ({ otherInfo }) => {
  const {
    email: rawEmail,
    setEmail: setRawEmail,
    location,
    dispatch,
  } = otherInfo;

  const [email, setEmail] = useState(rawEmail),
    [checked, setCheckbox] = useState(false),
    [isValidEmail, setValid] = useState(true),
    [submitted, setSubmitted] = useState(false),
    [err, setErr] = useState("");

  const handleChange = (e) => {
    let { value = "", name = "" } = e?.target || {};
    if (name === "email") {
      setEmail(value);
    } else {
      setCheckbox((checked) => !checked);
    }
  };
  const onBlur = () => {
    if (email?.length) {
      const isValid = checkEmail(email);
      setValid(isValid);
      /*if(isValid){
                setRawEmail(email)
            }*/
    }
  };

  const handleSubmit = () => {
    setSubmitted(true);
    if (isValidEmail && email && email.length) {
      const { from } = location.state || {
        from: { pathname: "/confirmation" },
      };
      const link = window.location.origin + "/profile";
      dispatch(userActions.verify(email, from, link));
    }
  };

  const onKeyPress = (e) => {
    if (e && e.key === "Enter") {
      checked ? handleSubmit() : setErr("Check the privacy and policy");
    }
  };

  const onSocialSignup = (profile) => {
    if (profile?.email) {
      const { from } = location.state || { from: { pathname: "/questions" } },
        { email, name = "" } = profile,
        splitName = name.split(" ");
      profile["firstName"] = splitName[0];
      profile["lastName"] = splitName.length > 1 ? splitName[1] : "";
      dispatch(userActions.login(email, null, from, profile));
    }
  };

  return (
    <>
      <div className="signUpLink">
        <div className="dontAccount">Have an account?</div>
        <div
          className="buttonSignIn"
          onClick={() => {
            history.push("/login");
          }}
        >
          <span className="signInText">Sign in</span>
        </div>
      </div>

      <div className={"header"}>
        <img src="images/logo.png" className={"logo"} />
        <span className={"signup-heading"}> Sign Up Free</span>
        <span className={"signup-sub-heading"}>Your Account</span>
      </div>
      <div className="content">
        <div className={"inputWrap"} style={{ padding: 0 }}>
          <Input
            config={{
              label: "Email",
              required: true,
              name: "email",
              type: "text",
              className: "signUpEmail",
              labelClass: "signUpEmailLabel",
              placeholder: "Enter Your Email",
            }}
            value={email}
            onChange={handleChange}
            onKeyPress={onKeyPress}
            onBlur={onBlur}
            isError={(submitted && email.trim() == "") || !isValidEmail}
            error={
              INPUT_ERRORS[
                submitted && email.trim() == "" ? "email" : "invalidEmail"
              ]
            }
          />
        </div>
        <div className="checkWrap" style={{ display: "block" }}>
          <div style={{ display: "inline-flex" }}>
            <Checkbox
              config={{
                name: "signup_checkbox",
                className: "signupCheckbox",
                checkedValue: checked,
              }}
              onChange={handleChange}
            />
            <span className={"checkBoxText"}>
              Creating an account means you’re okay with our
              <a
                target="_blank"
                href="https://voc.articence.com/form_ans/4461&ydkof244mjfhkih933klk30hgmn44$oj"
              >
                Terms of Service
              </a>
              , Privacy Policy, and our default Notification Settings.
            </span>
          </div>
          {err.length ? (
            <span className="invalid-feedback" style={{ position: "unset" }}>
              {err}
            </span>
          ) : null}
        </div>

        <Button
          text={"Sign Up"}
          customCSS={"signUpBtn"}
          btnTextClass={"signUpBtnText"}
          disable={!checked}
          clickHandler={handleSubmit}
        />
        <div className="divider">
          <span className="divider-text"></span>
          <span className="signText">Or sign up with</span>
          <span className="divider-text"></span>
        </div>
        <div className="social_login_button">
          <GoogleAcc showOnlyIcon={true} onSuccess={onSocialSignup} />
          <MicrosoftAcc showOnlyIcon={true} onSuccess={onSocialSignup} />
        </div>
        <span className="signUpTextContent">
          Articence is protected by reCAPTCHA and their Privacy Policy and{" "}
          <a
            target="_blank"
            href="https://voc.articence.com/form_ans/4461&ydkof244mjfhkih933klk30hgmn44$oj"
          >
            Terms of Service
          </a>{" "}
          apply.
        </span>
      </div>
    </>
  );
};

const SignUp = () => {
  const [email, setEmail] = useState(""),
    location = useLocation(),
    { loggingIn, error } = useSelector((state) => state.authentication),
    verification = useSelector((state) => state.users);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userActions.logout());
  }, []);

  const image = () => {
      return (
        <>
          <div className="bannerWrapper">
            <div className="bannerWrapper--left">
                <h1>Start your free trial today</h1>
                {/* <h2>No credit card required</h2> */}
                <p>The trial includes:</p>
                <ul>
                <li>
                    <DoneRoundedIcon />
                    Customized data based on your information
                </li>
                <li>
                    <DoneRoundedIcon />
                    Pre-configured dashboards and reports
                </li>
                <li>
                    <DoneRoundedIcon />
                    Customized templates and question banks
                </li>
                <li>
                    <DoneRoundedIcon />
                    Easy help and reference
                </li>
                <li>
                    <DoneRoundedIcon />
                    AI Analysis
                </li>
                </ul>
            </div>
            
            <div className="bannerWrapper--right">
                <img src="images/mainBannerFull.png" />
            </div>
            
          </div>
          {/* <img src="images/loginBanner.png" /> */}
        </>
      );
    },
    LeftWrapper = () => <Wrapper component={image} className={"leftWrapper"} />,
    RightWrapper = (props) => (
      <Wrapper
        component={SignUpContent}
        className={"rightWrapper"}
        otherInfo={{ ...props }}
      />
    ),
    Content = () => (
      <div className={"signInSinUpWrap sinUpWrap"}>
        <LeftWrapper />
        <RightWrapper
          email={email}
          setEmail={setEmail}
          location={location}
          dispatch={dispatch}
        />
      </div>
    );

  return (
    <CommonWrapper
      component={Content}
      isLoader={loggingIn || verification.verifying}
    />
  );
};

export default SignUp;
