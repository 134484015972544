import React,{useState,useEffect} from 'react';
import { Container, Typography, Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TablePagination } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Header, MainMenu } from '../Common/Header';
import RulesModal from '../CRM/CustomerTable/RulesModal';
import { rulesActions } from '../../actions/rulesActions'; 
import { useDispatch, useSelector } from "react-redux";
import { RulesService } from '../../services/RulesService';
import { rulesConstants } from '../../constants/rulesConstants';
import Tooltip from '@mui/material/Tooltip';


const VoiceRulesTable = () => {
  const [openRulesModal, setOpenRulesModal] = useState(false);
  const [rulesData, setRulesData] = useState(null);
  const [editAIRule, setEditAIRule] = useState(false);
  const [selectedRuleId, setSelectedRuleId] = useState(null);
  const [filterText, setFilterText] = useState('');
  
  const dispatch = useDispatch();
  const isRuleAdded = useSelector((state) => state.rulesLogic.isRuleAdded);
  const isRuleDeleted = useSelector((state) => state.rulesLogic.isRuleDeleted);

  // useEffect(() => { 
  //   (async () => {
  //     const data = await RulesService.getRules();
  //     setRulesData(data?.data?.call_handling_rules);
  //   })();
  // }, []);
  
  useEffect(() => {
    const fetchRules = async () => {
      const data = await RulesService.getRules();
      setRulesData(data?.data?.call_handling_rules);
    };

    fetchRules();

    if (isRuleAdded) {
      
      fetchRules();
      dispatch({ type: rulesConstants.RESET_RULES_ADDED });
      dispatch({ type: rulesConstants.RESET_RULES_DELETED }); // Reset flag after re-fetch
    }
  }, [isRuleAdded,isRuleDeleted, dispatch]);


  const columns = ["Rule Name", "Condition", "Action", "AI Agent", "Start Date"];
  const rows = [
    {
      rule_id: 12,
      name: "Rule 1",
      condition: "Condition 1",
      action: "Action 1",
      ai_agent: "Agent A",
      sp_para: "Parameter A"
    }
  ];

  const handleRowClick = (rule_id) => {
    setSelectedRuleId(rule_id);
  };

  // const handleRulesSubmit = (ruleData) => {
  //   console.log('Rules submitted2:', ruleData);
  //   dispatch(rulesActions.addRules(ruleData))
  //   setOpenRulesModal(false);
  // };

  const handleRulesSubmit = (ruleData) => {

    if (editAIRule) {
      dispatch(rulesActions.editRule(ruleData, selectedRuleId));
    } else {
      dispatch(rulesActions.addRules(ruleData));
    }

    setOpenRulesModal(false);
  };

  const handleRulesClick = () => {
    setOpenRulesModal(true)
  }

  const handleEditClick = () => {
    setOpenRulesModal(true);
    setEditAIRule(true);
  }
  
  const filteredRules = rulesData ? rulesData.filter(rules => 
    rules.name.toLowerCase().includes(filterText.toLowerCase()) ||
    rules.condition.toLowerCase().includes(filterText.toLowerCase()) ||
    rules.action.toLowerCase().includes(filterText.toLowerCase()) ||
    rules.ai_agent.toLowerCase().includes(filterText.toLowerCase()) ||
    rules.sp_para.toLowerCase().includes(filterText.toLowerCase())
  ) : [];

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    return date.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
  }

  return (
    <div>
    <Header/>
    <MainMenu/>
    <Container sx={{ mt: 4 }}>
      <Typography variant="h5" component="h1" gutterBottom>
        AI Voice Rules
      </Typography>

      <Button variant="contained" color="primary" sx={{ mb: 2 }} onClick={handleRulesClick}>
        Add Rule
      </Button>

      <TextField
        variant="outlined"
        placeholder="Enter content to filter"
        fullWidth
        sx={{ mb: 2 }}
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
      />

      {/* <Button variant="contained" color="primary" sx={{ mb: 2 }}>
        Sort By
      </Button> */}

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((col, index) => (
                <TableCell key={index} sx={{ fontWeight: 'bold' }}>
                  {col}
                </TableCell>
              ))}
              <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {filteredRules && filteredRules.length > 0 ? (
            filteredRules.map((row) => (
              <Tooltip key={row.rule_id} title={row.sp_para} arrow  placement="top">
              <TableRow key={row.rule_id} onClick={() => handleRowClick(row.rule_id)} style={{ cursor: 'pointer' }}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.condition}</TableCell>
                <TableCell>{row.action}</TableCell>
                <TableCell>{row.ai_agent}</TableCell>
                <TableCell>{formatDate(row.from_date)}</TableCell>
                <TableCell>
                  <Button variant="contained" color="primary" size="small" 
                  // startIcon={<EditIcon />} 
                  sx={{ mr: 1, fontSize: '0.75rem', textTransform: 'none', whiteSpace: 'nowrap' }}
                  onClick={handleEditClick}>
                    Edit Rule
                  </Button>
                  <IconButton color="primary"
                  onClick={() => dispatch(rulesActions.deleteRule(row.rule_id))}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell> 
              </TableRow>
              </Tooltip>
            ))
          ):(
            <TableRow>
              <TableCell colSpan={columns.length + 1} align="center">
                No data available
              </TableCell>
            </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={1}
        rowsPerPage={10}
        page={0}
        onPageChange={() => {}}
        onRowsPerPageChange={() => {}}
      />
    </Container>
 
    <RulesModal
        open={openRulesModal}
        onClose={() => setOpenRulesModal(false)}        
        handleSubmit={handleRulesSubmit}
        editAIRule={editAIRule}
        selectedRuleId={selectedRuleId}
        setEditAIRule={setEditAIRule}
      />
    </div>
  );
};

export default VoiceRulesTable;
