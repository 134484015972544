import React, { useEffect, useState } from "react";
import {
  Typography,
  Divider,
  Box,
  Paper,
  Fade,
  Zoom,
  List,
  ListItem,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import {
  Call,
  Email,
  Feedback,
  AccountBalanceWallet,
  Dashboard,
} from "@mui/icons-material";
import CustomerDashboardContainer from "./CustomerDashboard";
import { User2Icon } from "lucide-react";
import { CrmService } from "../../services";

const CallDetailsPage = ({ customerInfo, customerId }) => {
  const [selectedTab, setSelectedTab] = useState("calls");
  const [callLogs, setCallLogs] = useState([]);
  const [emailLogs, setEmailLogs] = useState([]);
  const [feedbackLogs, setFeedbackLogs] = useState([]);

  // Mock data fetching
  useEffect(() => {
    (async () => {
      const callLogs = await CrmService.getAllCallsLogs(customerId);
      setCallLogs(callLogs.data);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const emailLogs = await CrmService.getEmailsHistory(customerId);
      setEmailLogs(emailLogs?.data?.email_logs);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const feedbackLogs = await CrmService.getFeedbackResponses(customerId);
      setFeedbackLogs(feedbackLogs?.data?.event_data);
    })();
  }, []);

  const engagementData = {
    calls: callLogs?.call_logs,
    emails: emailLogs?.map((email) => ({
      Subject: email.subject,
      text: email.body.replace(/<\/?[^>]+(>|$)/g, ""), // Strips HTML tags
      date: new Date(email.created_at).toLocaleDateString(),
      "No of Email seen": `${email.id} Nos`,
    })),
    feedbacks: feedbackLogs?.map((feedback) => ({
      customer_engagement: feedback.customer_engagement,
      customer_responsiveness: feedback.customer_responsiveness,
      positive_responses_count: feedback.positive_responses_count,
      survey_id: feedback?.survey_id,
    })),
  };

  const paymentHistory = [
    {
      text: "Payment for Invoice #1234",
      date: "2023-08-01",
      amount: "$100",
      status: "Completed",
    },
    {
      text: "Payment for Invoice #5678",
      date: "2023-09-01",
      amount: "$150",
      status: "Completed",
    },
  ];

  const menuItems = [
    { label: "Calls", icon: <Call />, key: "calls" },
    { label: "Emails", icon: <Email />, key: "emails" },
    { label: "Feedback", icon: <Feedback />, key: "feedbacks" },
    { label: "Payments", icon: <AccountBalanceWallet />, key: "payments" },
  ];

  return (
    <Box display="flex" height="60vh">
      {/* Sidebar Menu */}
      <Box
        sx={{
          minWidth: "240px",
          bgcolor: "#f5f5f5",
          borderRight: "1px solid #e0e0e0",
          p: 2,
        }}
      >
        <List>
          {menuItems.map((item) => (
            <ListItem
              button
              key={item.key}
              selected={selectedTab === item.key}
              onClick={() => setSelectedTab(item.key)}
              sx={{
                mb: 1,
                borderRadius: 2,
                bgcolor: selectedTab === item.key ? "#d1ecff" : "transparent",
                "&:hover": {
                  bgcolor: selectedTab === item.key ? "#d1ecff" : "#e3e3e3",
                },
              }}
            >
              {item.icon}
              <Typography
                variant="body1"
                sx={{
                  ml: 1,
                  fontWeight: selectedTab === item.key ? "bold" : "normal",
                }}
              >
                {item.label}
              </Typography>
            </ListItem>
          ))}
        </List>
      </Box>

      {/* Main Content */}
      <Box sx={{ flexGrow: 1, p: 3, overflow: "auto" }}>
        <Fade in={true} timeout={500}>
          <Box>
            {selectedTab === "calls" && (
              <ContentSection title="Call History">
                <StyledTable rows={engagementData.calls} />
              </ContentSection>
            )}
            {selectedTab === "emails" && (
              <ContentSection title="Email History">
                <StyledTable rows={engagementData.emails} />
              </ContentSection>
            )}
            {selectedTab === "feedbacks" && (
              <ContentSection title="Feedback Responses">
                <StyledTable rows={engagementData.feedbacks} />
              </ContentSection>
            )}
            {selectedTab === "payments" && (
              <ContentSection title="Payment History">
                <StyledTable rows={paymentHistory} />
              </ContentSection>
            )}
          </Box>
        </Fade>
      </Box>
    </Box>
  );
};

// ContentSection component remains unchanged
const ContentSection = ({ title, children }) => (
  <Box>
    <Typography variant="h6" sx={{ fontWeight: 600, mb: 2 }}>
      {title}
    </Typography>
    <Divider sx={{ mb: 2 }} />
    {children}
  </Box>
);

// StyledTable component remains unchanged
const StyledTable = ({ rows = [] }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const columns = rows.length > 0 ? Object.keys(rows[0]) : [];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedRows = rows.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Paper sx={{ borderRadius: 2, overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 400 }}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              {columns.map((col) => (
                <TableCell
                  key={col}
                  sx={{ fontWeight: "bold", borderBottom: "1px solid #ddd" }}
                >
                  {col.charAt(0).toUpperCase() + col.slice(1)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedRows.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  No data found
                </TableCell>
              </TableRow>
            ) : (
              paginatedRows.map((row, index) => (
                <TableRow key={index}>
                  {columns.map((col) => (
                    <TableCell key={col}>{row[col] || "N/A"}</TableCell>
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default CallDetailsPage;
