import { customCallConstant } from "../constants/customCallConstant";

export function customCall(state = { isCalling: false }, action) {
  console.log("action.type", action.type);
  switch (action.type) {
    case customCallConstant.REGISTER_SIP:
      return {
        ...state,
        isCalling: false,
        isAccepted: false,
        isHold: false,
        isRecording: false,
        ua: action.payload,
        callStatus: "Registered",
        transcription: "",
        callLogId: null,
        callType: null,
        firstPhone: null
      };

    case customCallConstant.GET_SESSION:
      return {
        ...state,
        session: action.payload,
      };

    case customCallConstant.GET_AUDIO_REF:
      return {
        ...state,
        isCalling: true,
        audioRef: action.payload,
      };

    case customCallConstant.MAKE_CALL:
      return {
        ...state,
        isAccepted: true,
        isCalling: true,
        dialerNum: action.payload,
        callStatus: "Calling",
      };

    case customCallConstant.SET_INCOMING_CALL:
      return {
        ...state,
        isCalling: true,
        isAccepted: false,
        incomingCall: action.payload.call,
        callerNumber: action.payload.callerNumber,
        callStatus: "Incoming Call",
        callType: action.payload.callType,
      };

    case customCallConstant.ACCEPT_CALL:
      if (state.incomingCall) {
        state.incomingCall.answer({
          mediaConstraints: { audio: true, video: false },
        });
      }
      return {
        ...state,
        isCalling: true,
        isAccepted: true,
        callStatus: "Call Connected",
      };

    case customCallConstant.MUTE_CALL:
      return {
        ...state,
        isCalling: true,
        isAccepted: true,
        isMuted: true,
        callStatus: "Call Muted",
      };

    case customCallConstant.UNMUTE_CALL:
      return {
        ...state,
        isCalling: true,
        isAccepted: true,
        isMuted: false,
        callStatus: "Call Connected",
      };

    case customCallConstant.HOLD_CALL:
      return {
        ...state,
        isHold: true,
        callStatus: "Call on Hold",
      };

    case customCallConstant.UNHOLD_CALL:
      return {
        ...state,
        isHold: false,
        callStatus: "Call Connected",
      };

    case customCallConstant.TRANSFER_CALL:
      return {
        ...state,
        isCalling: true,
        incomingCall: null,
        callerNumber: null,
        callStatus: "Call Transferred",
      };

    case customCallConstant.END_CALL:
      return {
        ...state,
        isCalling: false,
        incomingCall: null,
        callerNumber: null,
        callStatus: "Call Ended",
      };

    case customCallConstant.DECLINE_CALL:
      return {
        ...state,
        isCalling: false,
        incomingCall: null,
        callerNumber: null,
        callStatus: "Call Declined",
      };

    case customCallConstant.PROGRESS_CALL:
      return {
        ...state,
        isCalling: true,
        callStatus: "Call in Progress",
      };

    case customCallConstant.START_CAPTURE_VOICE:
      return {
        ...state,
        isRecording: true,
        callStatus: "Capturing Voice",
      };

    case customCallConstant.STOP_CAPTURE_VOICE:
      return {
        ...state,
        isRecording: false,
        callStatus: "Call Connected",
      };

    case customCallConstant.TRANSCRIPTION:
      return {
        ...state,
        transcription: action.payload,
      };

    case customCallConstant.STORE_CALL_LOGS:
      return {
        ...state,
        callLogId: action.payload.callLogId,
        firstPhone: action.payload.firstPhone,
      };

    default:
      return state;
  }
}
