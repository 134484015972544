import { appointmentConstants } from "../constants/appointmentConstants";

const initialState = {
  loading: false,
  isAppointmentAdded: false,
  appointments: [],
  message: "",
};

export function appointmentsLogic(state = initialState, action) {
  switch (action.type) {
    case appointmentConstants.ADD_APPOINTMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case appointmentConstants.ADD_APPOINTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        isAppointmentAdded: true, // Set success flag to true
        appointments: [...state.appointments, action.appointments], // Optional: update agents list in state if needed
      };
    case appointmentConstants.ADD_APPOINTMENT_FAILURE:
      return {
        ...state,
        loading: false,
        message: action.error,
        type: "error",
      };
      case appointmentConstants.RESET_APPOINTMENT_ADDED:
  return {
    ...state,
    isAppointmentAdded: false,
  };

    default:
      return state;
  }
}
