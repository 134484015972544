import React from 'react';
import cn from 'classnames';
import Label from './Label';
import '../inputsFields.css';

const yesValue = true;
const Checkbox = (props) => {
    const { config: { label, name, options = {}, inputStyle, className, labelClass, checkedValue = null }, value, readOnly=false, onChange, onBlur, isDisabled, onClick } = props;
    let { yes = yesValue, text } = options,
        checked = checkedValue !== null ? checkedValue : value === yes;
    return (<label>
        <Label config={{ label, className: labelClass }} />
        <input className={cn('checkBoxField', className)} type='checkbox' name={name} onChange={onChange} readOnly={readOnly} onBlur={onBlur} disabled={isDisabled} style={inputStyle} checked={checked} />
    </label>)
};

export default Checkbox;