import React, { useState, useEffect } from "react";
import { Header, MainMenu } from "../../Common/Header";
import { Container, Row, Col, Form, Image, Tab, Nav } from "react-bootstrap";
import profileImage from "../../../assets/images/profile.png";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { crmActions } from "../../../actions";
import CallDetailsPage from "../../../components/Dialer/CallDetails2";

const MoreCustomerDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [selectedDetail, setSelectedDetail] = useState(null);

  useEffect(() => {
    if (id) {
      dispatch(crmActions.getCustomerDataById(id));
    }
  }, [dispatch, id]);

  const customer = useSelector(
    (state) => state?.crmLogic?.message?.data?.customer
  );
  console.log(customer, "<===customerData from API");

  const handleDetailClick = (detail) => {
    setSelectedDetail(detail);
  };

  return (
    <div>
      <Header />
      <MainMenu />
      <Container fluid className="my-5 p-0">
        <h4 style={{ marginLeft: "50px" }}>Customer Details Info - </h4>
        <Tab.Container defaultActiveKey="profile">
          <Nav variant="tabs" className="justify-content-center">
            <Nav.Item>
              <Nav.Link eventKey="profile">Profile</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="contact">Contact</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="company">Company</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="social">Social</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="statistics">Statistics</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="other">Other</Nav.Link>
            </Nav.Item>
          </Nav>
          <Row className="d-flex justify-content-between m-0">
            <Col md={3} className="text-center">
              <Image
                src={profileImage}
                roundedCircle
                fluid
                style={{
                  width: "150px",
                  height: "150px",
                  border: "2px solid #000",
                  padding: "3px",
                  objectFit: "cover",
                }}
              />
              <div className="mt-2">
                <h5>
                  {customer?.title} {customer?.first_name} {customer?.last_name}
                </h5>
                <p>{customer?.email}</p>
                <p>{customer?.first_phone}</p>
              </div>
            </Col>
            <Col md={9}>
              <Tab.Content className="mt-4">
                <Tab.Pane eventKey="profile">
                  <Form>
                    <Row className="mb-3">
                      <Form.Group as={Col}>
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                          type="text"
                          value={customer?.title || ""}
                          readOnly
                        />
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          type="text"
                          value={customer?.city || ""}
                          readOnly
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col}>
                        <Form.Label>Country</Form.Label>
                        <Form.Control
                          type="text"
                          value={customer?.country || ""}
                          readOnly
                        />
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>State</Form.Label>
                        <Form.Control
                          type="text"
                          value={customer?.state || ""}
                          readOnly
                        />
                      </Form.Group>
                    </Row>
                  </Form>
                </Tab.Pane>
                <Tab.Pane eventKey="contact">
                  <Form>
                    <Row className="mb-3">
                      <Form.Group as={Col}>
                        <Form.Label>Company</Form.Label>
                        <Form.Control
                          type="text"
                          value={customer?.company || ""}
                          readOnly
                        />
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>Company Address</Form.Label>
                        <Form.Control
                          type="text"
                          value={customer?.company_address || ""}
                          readOnly
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col}>
                        <Form.Label>Company City</Form.Label>
                        <Form.Control
                          type="text"
                          value={customer?.company_city || ""}
                          readOnly
                        />
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>Company Country</Form.Label>
                        <Form.Control
                          type="text"
                          value={customer?.company_country || ""}
                          readOnly
                        />
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>Company Phone</Form.Label>
                        <Form.Control
                          type="text"
                          value={customer?.company_phone || ""}
                          readOnly
                        />
                      </Form.Group>
                    </Row>
                  </Form>
                </Tab.Pane>
                <Tab.Pane eventKey="company">
                  <Form>
                    <Row className="mb-3">
                      <Form.Group as={Col}>
                        <Form.Label>Annual Revenue</Form.Label>
                        <Form.Control
                          type="text"
                          value={customer?.annual_revenue || ""}
                          readOnly
                        />
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>Number of Employees</Form.Label>
                        <Form.Control
                          type="text"
                          value={customer?.number_of_employees || ""}
                          readOnly
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col}>
                        <Form.Label>Industry</Form.Label>
                        <Form.Control
                          type="text"
                          value={customer?.industry || ""}
                          readOnly
                        />
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>Technologies</Form.Label>
                        <Form.Control
                          type="text"
                          value={customer?.technologies || ""}
                          readOnly
                        />
                      </Form.Group>
                    </Row>
                  </Form>
                </Tab.Pane>
                <Tab.Pane eventKey="social">
                  <Form>
                    <Row className="mb-3">
                      <Form.Group as={Col}>
                        <Form.Label>LinkedIn URL</Form.Label>
                        <Form.Control
                          type="text"
                          value={customer?.person_linkedin_url || ""}
                          readOnly
                        />
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>Twitter URL</Form.Label>
                        <Form.Control
                          type="text"
                          value={customer?.twitter_url || ""}
                          readOnly
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col}>
                        <Form.Label>Facebook URL</Form.Label>
                        <Form.Control
                          type="text"
                          value={customer?.facebook_url || ""}
                          readOnly
                        />
                      </Form.Group>
                    </Row>
                  </Form>
                </Tab.Pane>
                <Tab.Pane eventKey="statistics">
                  {/* <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                    <div onClick={() => handleDetailClick('emailSent')} style={{ cursor: "pointer", margin: "5px 0" }}>
                      <span>Email Sent: </span><span style={{ background: "#ccc", borderRadius: "30px", width:"40px",height:"30px", padding: "5px" }}>10</span>
                    </div>
                    <div onClick={() => handleDetailClick('emailReplied')} style={{ cursor: "pointer", margin: "5px 0" }}>
                      <span>Email Replied: </span><span style={{ background: "#ccc", borderRadius: "30px", width:"40px",height:"30px",  padding: "5px" }}>5</span>
                    </div>
                    <div onClick={() => handleDetailClick('surveySent')} style={{ cursor: "pointer", margin: "5px 0" }}>
                      <span>Survey Sent: </span><span style={{ background: "#ccc", borderRadius: "30px", width:"40px",height:"30px",  padding: "5px" }}>8</span>
                    </div>
                    <div onClick={() => handleDetailClick('surveyReplied')} style={{ cursor: "pointer", margin: "5px 0" }}>
                      <span>Survey Replied: </span><span style={{ background: "#ccc", borderRadius: "30px", width:"40px",height:"30px",  padding: "5px" }}>3</span>
                    </div>
                  </div>
                  {selectedDetail && (
                    <div style={{ marginTop: "20px" }}>
                      <h4>Details for {selectedDetail.replace(/([A-Z])/g, ' $1')}</h4>
                      <table style={{ width: "100%", borderCollapse: "collapse",  }}>
                        <thead style={{background: "#8FBC8F"}} >
                          <tr>
                            <th style={{ border: "1px solid #000", padding: "5px" }}>Date of Call</th>
                            <th style={{ border: "1px solid #000", padding: "5px" }}>Call Duration</th>
                            <th style={{ border: "1px solid #000", padding: "5px" }}>Call Summary</th>
                            <th style={{ border: "1px solid #000", padding: "5px" }}>Call Text</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style={{ border: "1px solid #000", padding: "5px" }}>2024-05-23</td>
                            <td style={{ border: "1px solid #000", padding: "5px" }}>15 min</td>
                            <td style={{ border: "1px solid #000", padding: "5px" }}>Positive</td>
                            <td style={{ border: "1px solid #000", padding: "5px" }}>Lorem ipsum dolor sit amet.</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )} */}

                  <CallDetailsPage customerInfo={[]} customerId={id} />
                </Tab.Pane>
                <Tab.Pane eventKey="other">
                  <Form>
                    <Row className="mb-3">
                      <Form.Group as={Col}>
                        <Form.Label>Account Owner</Form.Label>
                        <Form.Control
                          type="text"
                          value={customer?.account_owner || ""}
                          readOnly
                        />
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>Account Team</Form.Label>
                        <Form.Control
                          type="text"
                          value={customer?.account_team || ""}
                          readOnly
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col}>
                        <Form.Label>Departments</Form.Label>
                        <Form.Control
                          type="text"
                          value={customer?.departments || ""}
                          readOnly
                        />
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>Stage</Form.Label>
                        <Form.Control
                          type="text"
                          value={customer?.stage || ""}
                          readOnly
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col}>
                        <Form.Label>Keywords</Form.Label>
                        <Form.Control
                          type="text"
                          value={customer?.keywords || ""}
                          readOnly
                        />
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>Timezone</Form.Label>
                        <Form.Control
                          type="text"
                          value={customer?.timezone || ""}
                          readOnly
                        />
                      </Form.Group>
                    </Row>
                  </Form>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </div>
  );
};

export default MoreCustomerDetails;
