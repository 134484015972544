import { combineReducers } from "redux";
import { survey } from "./surveyReducer";
import { audience } from "./audienceReducer";
import { pageLogic } from "./pageLogicReducer";
import { subscription } from "./subscriptionReducer";

import { authentication } from "./authenticationReducer";
import { registration } from "./registrationReducer";
import { users, profilePic } from "./usersReducer";
import { queryBuilder } from "./queryBuilderReducer";

import {
  campaign,
  campaigns,
  details,
  detail,
  download,
  experience,
  questDesc,
  answers,
  categories,
  recommended,
  api_recommended,
  summary,
  customCat,
  pivotState,
  getQueriesById,
} from "./campaignsReducer";
import { alert } from "./alertReducer";
import { team } from "./teamReducer";
import { organization } from "./organizationReducer";
import { respondent } from "./respondentReducer";
import { latestSurveys } from "./dashboardReducer";
//import { emailLinks, getAudience, createGroup, getGroups, updateLink, getPersonByEmail, updateGeneralGroupId, updatePersonResponse, getAnswersInGroup, createGeneralGroup, getAnswersInAllGroups,saveSurveyRec} from './emailReducer';
import { similarCases } from "./similarCasesReducer";
import { themes } from "./dashboardReducer";
import { crmLogic } from "./crmReducer";
import { callAi } from "./callReducer";
import { customCall } from "./customCallReducer";
import { agentsLogic } from "./agentsReducer";
import { appointmentsLogic } from "./appointmentReducer";


import {
  quesDesc,
  questionBank,
  wsDetails,
  addData,
  bulkEmailCreate,
  shareSurveyByEmail,
  allWsDetails,
  workspaceByTeam,
  workspaceByUser,
  templateToSurvey,
  wsDetailsAuth,
} from "./workSpaceReducer";
import dropDown from "./dropDownReducer";
import {
  emailLinks,
  getAudience,
  createGroup,
  getGroups,
  updateLink,
  getPersonByEmail,
  updatePersonResponse,
  updateGeneralGroupId,
  getAnswersInGroup,
  createGeneralGroup,
  getAnswersInAllGroups,
  saveSurveyRec,
  updateSurveySent,
} from "./emailReducer";
import { rulesLogic } from "./rulesReducer";

const allReducers = combineReducers({
  authentication,
  registration,
  team,
  organization,
  users,
  campaign,
  campaigns,
  detail,
  details,
  download,
  questDesc,
  answers,
  alert,
  categories,
  summary,
  recommended,
  api_recommended,
  experience,
  customCat,
  emailLinks,
  getAudience,
  createGroup,
  wsDetailsAuth,
  updateLink,
  getPersonByEmail,
  updatePersonResponse,
  getAnswersInGroup,
  createGeneralGroup,
  getGroups,
  getAnswersInAllGroups,
  latestSurveys,
  saveSurveyRec,
  pivotState,
  getQueriesById,
  updateSurveySent,
  updateGeneralGroupId,
  similarCases,
  survey,
  audience,
  questionBank,
  quesDesc,
  wsDetails,
  addData,
  bulkEmailCreate,
  shareSurveyByEmail,
  allWsDetails,
  workspaceByTeam,
  workspaceByUser,
  themes,
  templateToSurvey,
  pageLogic,
  subscription,
  queryBuilder,
  profilePic,
  crmLogic,
  callAi,
  dropDown,
  customCall,
  agentsLogic,
  rulesLogic,
  appointmentsLogic
});

const rootReducer = (state, action) => {
  if (action.type === "USERS_LOGOUT") {
    state = undefined;
  }
  if (
    ["GET_WS_BY_USER_REQUEST", "CREATE_WS_DETAILS_REQUEST"].indexOf(
      action.type
    ) > -1 &&
    state &&
    state.hasOwnProperty("wsDetailsAuth")
  ) {
    state.wsDetailsAuth = {};
    state.getAudience = {};
  }
  return allReducers(state, action);
};

export { rootReducer };

const surveyReducer = combineReducers({
  respondent,
});

export { surveyReducer };
