import React, { useState } from "react";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  IconButton,
  Box,
  Typography,
  Paper,
  InputBase,
  Stack,
} from "@mui/material";
import { Search, Info as InfoIcon, Call } from "@mui/icons-material";
import { deepOrange } from "@mui/material/colors";
import CallDetailsPopup from "./CallDetails";
import { useDispatch } from "react-redux";
import { customCallActions } from "../../actions/customCallActions";
import CallMissedIcon from "@mui/icons-material/CallMissed";
import CallMadeIcon from "@mui/icons-material/CallMade";
import { Grid } from "antd";
import { formatCallDuration, formatDateTime } from "../../helpers/helper";

const DialCommonList = ({
  data = [],
  onInfoClick,
  listType = "Contacts",
  avatarColor = deepOrange[500],
  renderEmptyMessage = "No items found.",
  handleClose,
}) => {
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedData, setSelectedData] = useState(null);

  const dispatch = useDispatch();

  // Filter logic based on search input
  const filteredData = data?.filter((item) =>
    `${item.first_name} ${item.last_name}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  // Open popup with selected data
  const handleInfoClick = (item) => {
    setSelectedData(item); // Set the selected data
    setOpen(true); // Open the popup
    onInfoClick && onInfoClick(item); // Optional callback
  };

  const closePopup = () => {
    setOpen(false);
    setSelectedData(null); // Clear selected data on close
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        bgcolor: "background.paper",
        padding: 2,
      }}
    >
      <Box sx={{ width: "100%", maxWidth: 400, margin: "auto" }}>
        {/* Search Input */}
        <Paper
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            boxShadow: 3,
            "&:hover": { boxShadow: 5 },
            transition: "box-shadow 0.3s ease-in-out",
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder={`Search ${listType}`}
            inputProps={{ "aria-label": "search" }}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <Search />
          </IconButton>
        </Paper>
      </Box>

      <List sx={{ width: "100%", height: "100%" }}>
        {filteredData.length > 0 ? (
          filteredData.map((item, index) => (
            <ListItem
              key={index}
              sx={{
                borderRadius: "8px",
                border: "1px solid",
                borderColor: "divider",
                marginBottom: 1,
                width: "100%",
                padding: 2,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                "&:hover": { bgcolor: "action.hover" },
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ListItemAvatar>
                  <Avatar
                    sx={{
                      bgcolor: avatarColor,
                      width: { xs: 40, sm: 50 },
                      height: { xs: 40, sm: 50 },
                    }}
                  >
                    {/* {item.first_name.charAt(0)} */}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant="subtitle1">
                      {/* {item.first_name} {item.last_name} */}{" "}
                      {item.first_name
                        ? item.first_name + item.last_name
                        : item?.phone_number}
                    </Typography>
                  }
                  secondary={
                    listType === "Contacts" ? (
                      <Typography variant="body2" color="text.secondary">
                        {item.company}
                      </Typography>
                    ) : (
                      <Typography variant="body2" color="text.secondary">
                        {formatCallDuration(item?.call_duration)} <br/> {formatDateTime(item?.call_end_time)}
                      </Typography>
                    )
                  }
                  sx={{
                    "& .MuiListItemText-primary": { fontWeight: "medium" },
                  }}
                />
              </Box>
              <Stack direction="row" spacing={1}>
                {listType === "Recents" && (
                  <IconButton
                    edge="end"
                    aria-label="info"
                    sx={{ color: "primary.main" }}
                  >
                    {item?.call_type === "outbound" ? (
                      <CallMadeIcon />
                    ) : (
                      <CallMissedIcon color="error" />
                    )}
                  </IconButton>
                )}

                <IconButton
                  edge="end"
                  aria-label="info"
                  onClick={() => {
                    dispatch(
                      customCallActions.makeCall(
                        item?.first_phone ||
                          item?.mobile_phone ||
                          item?.phone_number
                      )
                    );
                    handleClose();
                  }}
                  sx={{ color: "primary.main" }}
                >
                  <Call />
                </IconButton>
                {listType === "Contacts" && (
                  <IconButton
                    edge="end"
                    aria-label="info"
                    onClick={() => handleInfoClick(item)}
                    sx={{ color: "primary.main" }}
                  >
                    <InfoIcon />
                  </IconButton>
                )}
              </Stack>
            </ListItem>
          ))
        ) : (
          <Typography
            variant="body1"
            color="text.secondary"
            align="center"
            sx={{ mt: 4 }}
          >
            {renderEmptyMessage}
          </Typography>
        )}
      </List>

      {/* Call Details Popup */}
      {selectedData && (
        <CallDetailsPopup
          open={open}
          onClose={closePopup}
          customerInfo={selectedData}
          customerId={selectedData.customer_id || selectedData.id}
          callHistory={selectedData.callHistory || []}
          previousWork={selectedData.previousWork || "No previous work."}
          paymentHistory={selectedData.paymentHistory || []}
          feedback={selectedData.feedback || "No feedback available."}
        />
      )}
    </Box>
  );
};

export default DialCommonList;
