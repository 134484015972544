import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";

import {
  Table,
  Form,
  Dropdown,
  DropdownButton,
  Pagination,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getUser, history } from "../../helpers";
import Tooltip from "@mui/material/Tooltip";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import IconButton from "@mui/material/IconButton";
import InfoModal from "../CRM/CustomerTable/InfoModal";
import Compose from "../CRM/CustomerTable/EmailSetupCustomerTable/Compose";
import DeleteIcon from "@mui/icons-material/Delete";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { crmActions } from "../../actions";
import CallModal from "../CRM/CustomerTable/CallModal";
import { Box, Checkbox, Chip, Stack, Button } from "@mui/material";
import { getBusinessStatus } from "../../helpers/getBizHours";
import { DeleteOutlined } from "@mui/icons-material";

const CommonTable = ({ dataSource, handleCustomerAdd, handleShow }) => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [customerId, setCustomerId] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [showCompose, setShowCompose] = useState(false);
  const [showCall, setShowCall] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [selectedRowsWithTimezone, setSelectedRowsWithTimezone] = useState([]);
  const [selectedData, setSelectedData] = useState([]);

  // Select All
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [filterQuery, setFilterQuery] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (dataSource) {
      setData(dataSource);
    }
  }, [dataSource]);

  const sortData = (field) => {
    setSortField(field);
    const sortedData = [...data].sort((a, b) => {
      if (a[field] < b[field]) return sortOrder === "asc" ? -1 : 1;
      if (a[field] > b[field]) return sortOrder === "asc" ? 1 : -1;
      return 0;
    });
    setData(sortedData);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const filterData = (e) => {
    const query = e.target.value.toLowerCase();
    setFilterQuery(query);
    setCurrentPage(1); // Reset to first page on filter change
  };

  const filteredData = data.filter(
    (item) =>
      item.first_name.toLowerCase().includes(filterQuery) ||
      item.email.toLowerCase().includes(filterQuery) ||
      item.country.toLowerCase().includes(filterQuery) ||
      item.company.toLowerCase().includes(filterQuery) ||
      item.title.toLowerCase().includes(filterQuery)
  );

  console.log("filteredData", filteredData);

  // Pagination calculations
  const lastItemIndex = currentPage * itemsPerPage;
  const firstItemIndex = lastItemIndex - itemsPerPage;
  const currentItems = filteredData.slice(firstItemIndex, lastItemIndex);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Create pagination items
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbers = 10;
    const halfPageNumbers = Math.floor(maxPageNumbers / 2);

    let startPage = Math.max(currentPage - halfPageNumbers, 1);
    let endPage = Math.min(currentPage + halfPageNumbers, totalPages);

    if (startPage <= halfPageNumbers) {
      endPage = Math.min(maxPageNumbers, totalPages);
    }
    if (endPage >= totalPages - halfPageNumbers) {
      startPage = Math.max(totalPages - maxPageNumbers + 1, 1);
    }

    for (let number = startPage; number <= endPage; number++) {
      pageNumbers.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => paginate(number)}
        >
          {number}
        </Pagination.Item>
      );
    }

    if (startPage > 1) {
      pageNumbers.unshift(<Pagination.Ellipsis key="start-ellipsis" />);
      pageNumbers.unshift(
        <Pagination.Item key={1} onClick={() => paginate(1)}>
          1
        </Pagination.Item>
      );
    }

    if (endPage < totalPages) {
      pageNumbers.push(<Pagination.Ellipsis key="end-ellipsis" />);
      pageNumbers.push(
        <Pagination.Item key={totalPages} onClick={() => paginate(totalPages)}>
          {totalPages}
        </Pagination.Item>
      );
    }

    return pageNumbers;
  };

  const handleSelectAll = (event) => {
    const checked = event.target.checked;
    setSelectAll(checked);

    if (checked) {
      // Select all current page items
      const allSelected = currentItems.map((item) => item.id);
      setSelectedRows(allSelected);
    } else {
      // Deselect all rows
      setSelectedRows([]);
    }
  };

  const handleRowSelect = (id) => {
    setSelectedRows((prevSelectedRows) => {
      const isSelected = prevSelectedRows.includes(id);

      if (isSelected) {
        // Deselect the row
        return prevSelectedRows.filter((rowId) => rowId !== id);
      } else {
        // Select the row
        return [...prevSelectedRows, id];
      }
    });
  };

  // Update selectedData and selectAll when selectedRows change
  // useEffect(() => {
  //   const getTimezoneOffset = (timezone) => {
  //     const date = new Date();
  //     const utcDate = new Date(
  //       date.toLocaleString("en-US", { timeZone: "UTC" })
  //     );
  //     const localDate = new Date(
  //       date.toLocaleString("en-US", { timeZone: timezone })
  //     );
  //     const diff = localDate - utcDate;
  //     return diff / (1000 * 60 * 60); // Convert to hours
  //   };

  //   // Check if selectedRows actually changed
  //   const updatedData = currentItems
  //     .filter((item) => selectedRows.includes(item.id)) // Only include selected rows
  //     .map((item) => [item.id, getTimezoneOffset(item.timezone)]); // Map to [id, offset]

  //   if (
  //     JSON.stringify(updatedData) !== JSON.stringify(selectedData) || // Check if data has changed
  //     selectAll !==
  //       (currentItems.length > 0 &&
  //         currentItems.every((item) => selectedRows.includes(item.id)))
  //   ) {
  //     setSelectedData(updatedData); // Store in selectedData

  //     setSelectAll(
  //       currentItems.length > 0 &&
  //         currentItems.every((item) => selectedRows.includes(item.id))
  //     );
  //   }
  // }, [selectedRows, currentItems, selectedData, selectAll]);

  const getTimezoneOffset = (timezone) => {
    try {
      if (!timezone) throw new Error("Invalid or missing timezone");
      const date = new Date();
      const utcDate = new Date(
        date.toLocaleString("en-US", { timeZone: "UTC" })
      );
      const localDate = new Date(
        date.toLocaleString("en-US", { timeZone: timezone })
      );
      const diff = localDate - utcDate;
      return diff / (1000 * 60 * 60); // Convert to hours
    } catch (error) {
      console.error("Error calculating timezone offset:", error.message);
      return 0; // Fallback to 0 offset if timezone is invalid
    }
  };

  useEffect(() => {
    // Filter and map selected rows
    const updatedData = currentItems
      .filter((item) => selectedRows.includes(item.id)) // Only include selected rows
      .map((item) => [
        item.id,
        getTimezoneOffset(item.timezone || "UTC"), // Use "UTC" as fallback timezone
      ]);

    // Update state only if data or selection state changes
    if (
      JSON.stringify(updatedData) !== JSON.stringify(selectedData) || // Check if data has changed
      selectAll !==
        (currentItems.length > 0 &&
          currentItems.every((item) => selectedRows.includes(item.id)))
    ) {
      setSelectedData(updatedData); // Store in selectedData
      setSelectAll(
        currentItems.length > 0 &&
          currentItems.every((item) => selectedRows.includes(item.id))
      );
    }
  }, [selectedRows, currentItems, selectedData, selectAll]);

  function sendSingleEmail(id, timezone) {
    console.log("sendSingleEmail", id);
    const timeZone = getTimezoneOffset(timezone);
    setSelectedRows([id, timeZone]);
    handleShowEmail();
  }

  // useEffect(() => {
  //   console.log(selectedRows, "<===selectedRows");
  //   setSelectAll(
  //     currentItems.length > 0 &&
  //       currentItems.every((item) => {
  //         console.log(item.timezone);
  //         selectedRows.includes(item.id);
  //       })
  //   );
  // }, [selectedRows, currentItems]);

  // ----------------------- GET BUSINESS HOURS ----------------------- //

  const handleView = (id) => {
    console.log(id, "<===id");
    history.push(`/viewEdit/${id}`); // Adjust the route as needed
  };

  // Function to navigate to the edit page
  const handleEdit = (id) => {
    console.log(id, "<==id");
    history.push(`/viewNotes/${id}`);
  };

  const handleShowEmail = (id) => {
    console.log(id, "<===item herer"); // Log the email
    setCustomerId(id);
    setShowCompose(true);
  };

  const handleShowCall = (id, first_phone) => {
    console.log("handleShowCall", id);
    setCustomerId(id);
    setPhoneNumber(first_phone);
    setShowCall(true);
  };

  const handleCloseInfoModal = () => {
    setShowInfoModal(false);
  };

  const handleCloseCompose = () => {
    setShowCompose(false);
  };

  const handleRedirect = (id) => {
    history.push(`/moreCustomerDetails/${id}`);
  };

  const handleShowInfoModal = (customer, event) => {
    const rect = event.target.getBoundingClientRect();
    setCursorPosition({
      x: rect.right + window.scrollX,
      y: rect.top + window.scrollY,
    });
    setSelectedCustomer(customer);
    setShowInfoModal(true);
  };

  const deleteCustomer = (Id) => {
    console.log(Id);
    console.log("delete Note trigger");
    dispatch(crmActions.deleteCustomer(Id));
    const userDetails = getUser();
    dispatch(crmActions.getAllCustomers(userDetails.user_id));
  };

  const badgeColor = {
    lunch: "warning",
    within: "success",
    outside: "error",
  };
  return (
    <div style={{ margin: "20px auto", border: "0px solid red" }}>
      <Form.Group className="mb-3" controlId="filterQuery">
        <Form.Control
          type="text"
          placeholder="Search by name or email..."
          onChange={filterData}
        />
      </Form.Group>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 2,
          pb: 2,
          px: 2,
        }}
      >
        <DropdownButton id="dropdown-basic-button" title="Sort By" size="sm">
          <Dropdown.Item onClick={() => sortData("first_name")}>
            First Name
          </Dropdown.Item>
          <Dropdown.Item onClick={() => sortData("last_name")}>
            Last Name
          </Dropdown.Item>
          <Dropdown.Item onClick={() => sortData("email")}>Email</Dropdown.Item>
        </DropdownButton>

        {selectedRows.length > 0 && (
          <Stack direction="row" spacing={2}>
            <Button
              size="sm"
              color="primary"
              variant="contained"
              startIcon={<MailOutlinedIcon />}
              onClick={handleShowEmail}
            >
              Compose
            </Button>
            {/* <Button
              color="error"
              variant="contained"
              size="sm"
              startIcon={<DeleteOutlined />}
            >
              Delete Selection
            </Button> */}
          </Stack>
        )}
      </Box>

      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                sx={{ padding: ".2rem !important" }}
              >
                <Checkbox checked={selectAll} onChange={handleSelectAll} />
              </TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Company</TableCell>
              <TableCell>Time Zone</TableCell>
              <TableCell>Biz Hours</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentItems.map((row) => (
              <TableRow
                key={row.first_name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ padding: "0rem !important" }}
                >
                  <Checkbox
                    checked={selectedRows.includes(row.id)}
                    onChange={() => handleRowSelect(row.id)}
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.first_name}
                </TableCell>
                <TableCell>{row.last_name}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.title}</TableCell>
                <TableCell>{row.company}</TableCell>
                <TableCell>{row.timezone}</TableCell>
                <TableCell>
                  <Chip
                    label={getBusinessStatus(row.timezone)?.message}
                    color={badgeColor[getBusinessStatus(row.timezone)?.status]}
                    variant="outlined"
                  />
                </TableCell>
                <TableCell>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        flexShrink: 0,
                        gap: "4px",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <Button
                        size="sm"
                        variant="primary"
                        onClick={() => handleView(row.id)}
                      >
                        View/Edit
                      </Button>
                      <Button
                        size="sm"
                        variant="primary"
                        onClick={() => handleShowCall(row.id, row.first_phone)}
                      >
                        Call
                      </Button>
                      <Button
                        size="sm"
                        variant="secondary"
                        onClick={() => handleEdit(row?.id)}
                      >
                        View Notes
                      </Button>
                    </div>
                    <div
                      style={{
                        border: "1px solid gray",
                        borderRadius: "4px",
                        padding: "4px 6px",
                        margin: "0px 4px",
                        cursor: "pointer",
                      }}
                      onClick={() => sendSingleEmail(row.id, row.timezone)}
                    >
                      <MailOutlinedIcon />
                    </div>
                    <Tooltip title="More details">
                      <IconButton
                        style={{
                          border: "1px solid gray",
                          borderRadius: "4px",
                          padding: "4px 6px",
                          margin: "0px 4px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleRedirect(row.id)}
                      >
                        <MoreHorizIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton
                        style={{
                          border: "1px solid gray",
                          borderRadius: "4px",
                          padding: "4px 6px",
                          margin: "0px 4px",
                          cursor: "pointer",
                        }}
                        onClick={() => deleteCustomer(row.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination>
        <Pagination.Prev
          onClick={() => paginate(currentPage > 1 ? currentPage - 1 : 1)}
        />
        {renderPageNumbers()}
        <Pagination.Next
          onClick={() =>
            paginate(currentPage < totalPages ? currentPage + 1 : totalPages)
          }
        />
      </Pagination>
      {showCompose && (
        <Compose
          customerId={customerId}
          show={showCompose}
          handleClose={handleCloseCompose}
          isSchedule={true}
          customers={selectedData}
        />
      )}
      {showCall && (
        <CallModal
          isVisible={showCall}
          customerId={customerId}
          phoneNumber={phoneNumber}
          onClose={() => setShowCall(false)}
        />
      )}
      <InfoModal
        show={showInfoModal}
        handleClose={handleCloseInfoModal}
        customer={selectedCustomer}
        position={cursorPosition}
      />
    </div>
  );
};

export default CommonTable;
