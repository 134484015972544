import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Fab, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Header, MainMenu } from "../../Common/Header";
import CalendarModal from "./CalendarModal";
import { useDispatch, useSelector } from "react-redux";
import { appointmentActions } from "../../../actions/appointmentActions";
import { appointmentService } from "../../../services/AppointmentService";
import { appointmentConstants } from "../../../constants/appointmentConstants";
import { getAccessToken, getCalendarEvents  } from "../../../components/Email_Integration/Outlook";

const localizer = momentLocalizer(moment);

const VocComponent = () => {
  const dispatch = useDispatch();
  const isAppointmentAdded = useSelector((state) => state.appointmentsLogic.isAppointmentAdded);

  const [appointments, setAppointments] = useState([]);
  const [editAppointments, setEditAppointments] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [formValues, setFormValues] = useState({
    title: "",
    Organizer: "",
    organizerId: null,
    location: "",
    start: "",
    end: "",
    people: [],
    peopleIds: [],
    description: "",
  });
  const [saveTriggered, setSaveTriggered] = useState(false);

  // Fetch appointments from API
  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const response = await appointmentService.getAppointments();
  //       const mappedAppointments = response?.data?.map((appt) => ({
  //         id: appt.id,
  //         title: appt.title,
  //         start: new Date(appt.start_time),
  //         end: new Date(appt.end_time),
  //         location: appt.location,
  //         organizerId: appt.organizer_id,
  //         description: appt.description,
  //         attendees: appt.attendees,
  //       }));
  //       setAppointments(mappedAppointments || []);
  //     } catch (error) {
  //       console.error("Error fetching appointments:", error);
  //     }
  //   })();
  // }, []);
//outlook
  const fetchOutlookEvents = () => {
    getAccessToken((res, err) => {
      if (err) {
        console.error("Error getting access token:", err);
        return;
      }
      const { accessToken } = res;
      getCalendarEvents(accessToken, (events, error) => {
        if (error) {
          console.error("Error fetching calendar events:", error);
          return;
        }
        setAppointments(events);
      });
    });
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user?.social_type === "outlook") {
    fetchOutlookEvents();}
  }, []);

  console.log("appointments",appointments);
  

  const fetchAppointments = async () => {
    try {
      const response = await appointmentService.getAppointments();
      const mappedAppointments = response?.data?.map((appt) => ({
        id: appt.id,
        title: appt.title,
        start: new Date(appt.start_time),
        end: new Date(appt.end_time),
        location: appt.location,
        organizerId: appt.organizer_info.id,
        description: appt.description,
        attendees: appt.attendees,
        organizerName: appt.organizer_info.firstname
      }));
      setAppointments(mappedAppointments || []);
    } catch (error) {
      console.error("Error fetching appointments:", error);
    }
  };

  useEffect(() => {
    fetchAppointments();

    if (isAppointmentAdded || saveTriggered) {
      fetchAppointments();
      dispatch({ type: appointmentConstants.RESET_APPOINTMENT_ADDED }); // Reset flag after re-fetch
    }
  }, [isAppointmentAdded, dispatch, saveTriggered]);

  

  const handleSelectAppointment = (appointment) => {

    setEditAppointments(true);
    setSelectedAppointment(appointment);
    setFormValues({
      title: appointment.title,
      Organizer: appointment.organizerName,
      organizerId: appointment.organizerId,
      location: appointment.location,
      start: appointment.start,
      end: appointment.end,
      people: appointment.attendees?.customer_info?.map((a) => a.firstname) || [],
      peopleIds: appointment.attendees?.customer_info?.map((a) => a.id) || [],
      description: appointment.description,
    });
    setModalOpen(true);
    setSaveTriggered(false);
  };

  const handleAddClick = () => {
    setSelectedAppointment(null);
    setFormValues({
      title: "",
      Organizer: "",
      organizerId: "",
      location: "",
      start: new Date(),
      end: new Date(),
      people: [],
      peopleIds: [],
      description: "",
    });
    setModalOpen(true);
  };

  const handleSaveTrigger = () => {
    setSaveTriggered(true);
    
  };

  const handleSave = (newAppointment) => {
    
    if(selectedAppointment!== null){
      dispatch(appointmentActions.editAppointments(newAppointment, selectedAppointment.id));
    } else{
      dispatch(appointmentActions.addAppointments(newAppointment));
    }
  
    setModalOpen(false);
    setSelectedAppointment(null);
    setSaveTriggered(false);
  };

  const handleDelete = () => {
    if (selectedAppointment) {      
      setAppointments((prev) => prev.filter((appt) => appt.id !== selectedAppointment.id));
      setModalOpen(false);
      dispatch(appointmentActions.deleteAppointments(selectedAppointment.id))
      setSelectedAppointment(null);
    }

    
  };

  return (
    <div>
      <Header />
      <MainMenu />
      <div style={{ padding: 20 }}>
        <Typography variant="h4" gutterBottom>
          Calendar
        </Typography>
        <Calendar
          localizer={localizer}
          events={appointments}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500, marginBottom: 20 }}
          selectable
          onSelectEvent={handleSelectAppointment}
        />
        <Fab
          color="primary"
          aria-label="add"
          onClick={handleAddClick}
          style={{ position: "fixed", bottom: 20, right: 20 }}
        >
          <AddIcon />
        </Fab>
        <CalendarModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          onSave={handleSave}
          onDelete={handleDelete}
          formValues={formValues}
          setFormValues={setFormValues}
          selectedAppointment={selectedAppointment}
          editAppointments={editAppointments}
          appointments={appointments}
          onSaveTrigger={handleSaveTrigger}
        />
      </div>
    </div>
  );
};

export default VocComponent;
