export function getBusinessStatus(timeZone) {
  const effectiveTimeZone = timeZone || 'UTC';
  var now = new Date(
    new Date().toLocaleString("en-US", { timeZone: effectiveTimeZone })
  );

  // Define business hours
  var startBusinessHours = new Date(now);
  startBusinessHours.setHours(9, 0, 0);

  var endBusinessHours = new Date(now);
  endBusinessHours.setHours(17, 0, 0);

  // Define lunch hours
  var startLunchHours = new Date(now);
  startLunchHours.setHours(13, 0, 0);

  var endLunchHours = new Date(now);
  endLunchHours.setHours(14, 0, 0);

  if (now >= startLunchHours && now < endLunchHours) {
    return { status: "lunch", message: "Lunch Hour" };
  } else if (now >= startBusinessHours && now < endBusinessHours) {
    return { status: "within", message: "Within Business Hours" };
  } else {
    return { status: "outside", message: "Outside Business Hours" };
  }
}
