import React, { useState, useEffect } from 'react';
import { Modal, Box, Grid, Tooltip, Typography, IconButton, TextField, Button, Select, MenuItem, FormControl, Chip, Checkbox, ListItemText } from "@mui/material";
import moment from "moment";
import CloseIcon from '@mui/icons-material/Close';
import { CrmService } from "../../../services";
import { appointmentService } from '../../../services/AppointmentService';



const peopleOptions = ["John", "Jane", "Alice", "Bob", "Mary"];

const CalendarModal = ({ open, onClose, onSave, onDelete, formValues, setFormValues, selectedAppointment, editAppointments, appointments, onSaveTrigger }) => {

  const [customerData, setCustomerData] = useState(null)
  const [organizerData, setOrganizerData] = useState(null)
  const [error, setError] = useState("");
  const [nameError, setNameError] = useState("");
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [editData, setEditData] = useState(null);


  const mandatoryFields = ["title", "organizerId", "location", "start", "end", "people"];

  // Validation for mandatory fields
  useEffect(() => {
    const allMandatoryFilled = mandatoryFields.every(
      (field) => formValues[field] && formValues[field].toString().trim() !== ""
    );
    setIsSaveEnabled(allMandatoryFilled && !error);
  }, [formValues, error]);


  const handleSaveClick = () => {
    if (!isSaveEnabled) return;

    if (error) return;
    const newAppointment = {
      title: formValues.title,
      organizer_id: formValues.organizerId,
      location: formValues.location,
      start_time: new Date(formValues.start).toISOString().replace("Z", "+00:00"),
      end_time: new Date(formValues.end).toISOString().replace("Z", "+00:00"),
      attendees: formValues.peopleIds,
      //appointment_source: formValues.source,
      description: formValues.description
    };
    onSave(newAppointment);
    onSaveTrigger();
    setError("");
  };

  useEffect(() => {
    (async () => {
      const data = await CrmService.getAllCustomers();
      setCustomerData(data?.data?.customers);
    })();
  }, []);

  useEffect(() => {
    if (!editAppointments) {
      (async () => {
        const data = await appointmentService.getUserExtension();
        setOrganizerData(data?.data);
      })();
    }
  }, []);

  // useEffect(() => {
  //   (async () => {
  //     const data = await appointmentService.getAppointmentsById(selectedAppointment.id);
  //     setEditData(data?.data);
      
  //   })();
  // }, []);

  const handlePeopleChange = (event) => {
    const selectedNames = event.target.value;
    const selectedPeople = customerData.filter((customer) => selectedNames.includes(customer.first_name));
    const selectedIds = selectedPeople.map((person) => person.id);

    setFormValues({
      ...formValues,
      people: selectedNames, // Display selected names
      peopleIds: selectedIds, // Store IDs for `to_name` in `newAppointment`
    });
  };

  const handleEndDateChange = (e) => {
    const newEndDate = new Date(e.target.value);
    setFormValues({ ...formValues, end: newEndDate });

    // Validation: Check if start date is after end date
    if (new Date(formValues.start) > newEndDate) {
      setError("End date cannot be earlier than start date");
    } else {
      setError(""); // Clear the error if validation passes
    }
  };

  const handleClose = () => {
    onClose();
    setError('');
  };




  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            color: 'grey.500',
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" gutterBottom>{selectedAppointment ? "Edit Appointment" : "Add Appointment"}</Typography>
        <Grid container spacing={1}>
          {/* Title Input */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={
                <>
                  {"Title"}
                  <Typography component="span" color="error" style={{ marginLeft: 4 }}>
                    *
                  </Typography>
                </>
              }
              margin="normal"
              value={formValues.title}
              onChange={(e) => {
                const newTitle = e.target.value.trim();

                // Check for duplicate title
                const isDuplicate = appointments.some(
                  (appointment) => appointment.title.toLowerCase() === newTitle.toLowerCase()
                );

                if (isDuplicate) {
                  setNameError("Title already exists");
                } else {
                  setNameError(""); // Clear the error if no duplicate
                }

                setFormValues({ ...formValues, title: newTitle });
              }}
              error={!!nameError}
              helperText={nameError}
            />
          </Grid>


          {/* Organizer Input */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              select
              label={
                <>
                  {"Organizer"}
                  <Typography component="span" color="error" style={{ marginLeft: 4 }}>
                    *
                  </Typography>
                </>
              }
              margin="normal"
              name="Organizer"
              value={formValues.Organizer}
              onChange={(e) => {
                const selectedCustomer = organizerData.find((customer) => customer.Name === e.target.value);
                setFormValues({
                  ...formValues,
                  Organizer: e.target.value,
                  organizerId: selectedCustomer.id,
                });
              }}
            >
              {organizerData?.map((customer) => (
                <MenuItem key={customer.id} value={customer.Name}>
                  {customer.Name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* Start Date & Time */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="datetime-local"
              label={
                <>
                  {"Start Date & Time"}
                  <Typography component="span" color="error" style={{ marginLeft: 4 }}>
                    *
                  </Typography>
                </>
              }
              margin="normal"
              value={moment(formValues.start).format("YYYY-MM-DDTHH:mm")}
              onChange={(e) => setFormValues({ ...formValues, start: new Date(e.target.value) })}
            />
          </Grid>

          {/* End Date & Time */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="datetime-local"
              label={
                <>
                  {"End Date & Time"}
                  <Typography component="span" color="error" style={{ marginLeft: 4 }}>
                    *
                  </Typography>
                </>
              }
              margin="normal"
              value={moment(formValues.end).format("YYYY-MM-DDTHH:mm")}
              onChange={handleEndDateChange}
              error={Boolean(error)}
              helperText={error}
            />
          </Grid>

          {/* Location */}
          <Grid item xs={12} >
            <TextField
              fullWidth
              label={
                <>
                  {"Location"}
                  <Typography component="span" color="error" style={{ marginLeft: 4 }}>
                    *
                  </Typography>
                </>
              }
              margin="dense"
              value={formValues.location}
              onChange={(e) => setFormValues({ ...formValues, location: e.target.value })}
            />
          </Grid>

          {/* Source */}
          {/* <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={
                <>
                  {"Source"}
                  <Typography component="span" color="error" style={{ marginLeft: 4 }}>
                    *
                  </Typography>
                </>
              }
              margin="dense"
              value={formValues.source}
              onChange={(e) => setFormValues({ ...formValues, source: e.target.value })}
            />
          </Grid> */}

          {/* People */}
          <Grid item xs={12}>
            <FormControl fullWidth margin="normal">
              <div id="people-select-label">People<><Typography component="span" color="error" style={{ marginLeft: 4 }}>
                *
              </Typography></></div>
              <Select
                labelId="people-select-label"
                multiple
                value={formValues.people}
                onChange={handlePeopleChange}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
              >
                {customerData?.map((customer) => (
                  <MenuItem key={customer.id} value={customer.first_name}>
                    <Checkbox checked={formValues.people.indexOf(customer.first_name) > -1} />
                    <ListItemText primary={customer.first_name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Description */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Description"
              multiline
              rows={4}
              margin="dense"
              value={formValues.description}
              onChange={(e) => setFormValues({ ...formValues, description: e.target.value })}
            />
          </Grid>

          {/* Action Buttons */}
          <Grid item xs={12}>
            <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
              <Tooltip
                title={!isSaveEnabled ? "Please fill all mandatory fields" : ""}
                placement="top"
              >
                <span>
                  <Button
                    variant="contained"
                    onClick={handleSaveClick}
                    disabled={!isSaveEnabled}
                  >
                    Save
                  </Button>
                </span>
              </Tooltip>
              {selectedAppointment && (
                <Button variant="outlined" color="error" onClick={onDelete}>
                  Delete
                </Button>
              )}
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
            </Box>
          </Grid>
        </Grid>

      </Box>
    </Modal>
  );
};

export default CalendarModal;
