export const fetchApolloUser = async (payload, endpoint = 'users') => {
  const baseUrl = 'https://api.apollo.io/api/v1';
  const url = `${baseUrl}/${endpoint}/search`;
  
  const options = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
      'x-api-key': 'LgqD-xqRGq0PEUFFY2Xd-g',
    },
    body: JSON.stringify(payload),
  };

  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error;
  }
};
