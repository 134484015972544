import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import ProfileImg from '../../assets/images/profile.png';
import BasicInfoSetting from './BasicInfo';
import ProfilePicSetting from './ProfilePic';
import PasswordSetting from './Password';
import AlertSetting from './Alert';
import PlanDetail from './PlanDetail';
import VoipInfoSetting from './VoipInfo';
import { userActions } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import api from "../../services/api";
import './Settings.scss';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import SetPlanDetails from "./SetPlanDetails";
import SMTPInfo from "./SMTPSettings";
import ApolloInfo from "./ApolloSettings";

function createData(amount, recurring, plan, validity, active, remarks) {
    return { amount, recurring, plan, validity, active, remarks };
}
  
const rows = [
    createData('$100', 159, "fgsfhffsdffdfdjsfjfjsdfjfsdj", "11/23/2023", "Y", "abcdefghijklmnopqrstuvwxyz"),
    createData('$100', 237, "fgsfhffsdffdfdjsfjfjsdfjfsdj", "11/23/2023", "Y", "abcdefghijklmnopqrstuvwxyz"),
    createData('$100', 262, "fgsfhffsdffdfdjsfjfjsdfjfsdj", "11/23/2023", "Y", "abcdefghijklmnopqrstuvwxyz"),
    createData('$100', 305, "fgsfhffsdffdfdjsfjfjsdfjfsdj", "11/23/2023", "Y", "abcdefghijklmnopqrstuvwxyz"),
    createData('$100', 356, "fgsfhffsdffdfdjsfjfjsdfjfsdj", "11/23/2023", "Y", "abcdefghijklmnopqrstuvwxyz"),
];

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const getCurrentPlan = async () => {
    const result = await api.get(`/currentPlan`);
    return result;
};

const Settings = ({settingsTab}) => {
    const [value, setValue] = useState(settingsTab);
    const [planData, setPlanData] = useState({});
    const [trigger, setTrigger] = useState(false);
    const picture = useSelector((state) => state.profilePic );
    const [img, setImg] = useState(picture?.items?.data?.profile_pic || "");
    const dispatch = useDispatch();

    useEffect(()=> {
        getCurrentPlan().then(res=>{
            if(res && res.status === 200 && res.data){
                setPlanData(res.data.current_plan);
            }
        }, error=>{
            console.log('error : ', error);
        });
    }, []);

    useEffect(()=>{
        setValue(settingsTab);
    }, [settingsTab]);

    useEffect(()=>{
        !picture?.items?.data?.profile_pic?.length && dispatch(userActions.getProfilePic());
    }, []);

    useEffect(()=>{
        if(picture && picture.items && picture.items.data){
            setImg(picture.items.data.profile_pic);
        }
    }, [picture]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const Image = ({showAddIcon=false}) => {
        const onAddClick = () => {
            showAddIcon && setTrigger(trigger => !trigger);
        };

        return <div className='profile__box' style={showAddIcon ? {}:{cursor:'default'}} onClick={onAddClick}>
            <div className='profile__photo'>
                <img src={img?.length ? img : ProfileImg} />
                { showAddIcon ? <AddCircleOutlineRoundedIcon /> : null }
            </div>
            { showAddIcon ? <div className='profile__text'>Add Profile Photo</div> : null }
        </div>
    };




    const [recurring, setRecurring] = React.useState('');

    const recurringHandler = (event) => {
        setRecurring(event.target.value);
    };

    return (
        <div className="mainPageBodyScroller mainSettings">
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="horizontal__tabs">
                    <Tabs value={value} onChange={handleChange} >
                        <Tab label="Basic Information" {...a11yProps(0)} />
                        <Tab label="Change Password" {...a11yProps(1)} />
                        <Tab label="Set Alerts" {...a11yProps(2)} />
                        <Tab label="Plan Details" {...a11yProps(3)} />
                        <Tab label="Set Payment API" {...a11yProps(4)} />
                        <Tab label="VoIP Details" {...a11yProps(5)} />
                        <Tab label="SMTP Details" {...a11yProps(6)} />
                        <Tab label="Apollo API" {...a11yProps(7)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0} className="tab__panel">
                    <div className='profile'>
                        <Image showAddIcon={true}/>
                        { trigger ? <ProfilePicSetting setTrigger={setTrigger}/> : <BasicInfoSetting/> }
                    </div>
                </TabPanel>
                <TabPanel value={value} index={1} className="tab__panel">
                    <div className='profile'>
                        <Image/>
                        <PasswordSetting />
                    </div>
                </TabPanel>
                <TabPanel value={value} index={2} className="tab__panel">
                    <div className='profile'>
                        <Image/>
                        <AlertSetting />
                    </div>
                </TabPanel>
                <TabPanel value={value} index={3} className="tab__panel">
                    <div className='profile'>
                        <Image/>
                        <PlanDetail data={planData}/>
                    </div>
                </TabPanel>
                <TabPanel value={value} index={4} className="tab__panel">
                    <div className='profile'>
                        <div className="profile-payment-api">
                            <Image/>
                            {/* <p>Set up phone number for text alerts based on responses, set for specific conditions.. Text alert is only available for US customers. If you would like to participate please fill up details.</p> */}
                        </div>
                        <SetPlanDetails />
                    </div>
                </TabPanel>
                <TabPanel value={value} index={5} className="tab__panel">
                    <div className='profile'>
                        <div className="profile-payment-api">
                            <Image/>
                        </div>
                        <VoipInfoSetting />
                    </div>
                </TabPanel>
                <TabPanel value={value} index={6} className="tab__panel">
                    <div className='profile'>
                        <div className="profile-payment-api">
                            <Image/>
                        </div>
                        <SMTPInfo />
                    </div>
                </TabPanel>
                <TabPanel value={value} index={7} className="tab__panel">
                    <div className='profile'>
                        <div className="profile-payment-api">
                            <Image/>
                        </div>
                        <ApolloInfo />
                    </div>
                </TabPanel>
            </Box>
        </div>
    );
};

export default Settings;
