import { useEffect, useRef, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import JsSIP from "jssip";
import io from "socket.io-client";
import { customCallActions } from "../actions/customCallActions";
import { CrmService } from "../services";

const {
  registerSIP,
  setIncommingCall,
  declineCallRequest,
  getAudioRef,
  startCaptureVoice,
  stopCaptureVoice,
  getSession,
  updateCallLogs,
  setTranscription,
} = customCallActions;

const SOCKET_URL = "https://testvocapp-ws.articence.com";
const SIP_WS_URL = "wss://pbx.articence.com:8089/ws";

const SIP_URI = "sip:1002@pbx.articence.com";
const SIP_PASSWORD = "3dad3e8534cc407efc50efd127356075";

const REGISTRATION_INTERVAL = 120;
const HEARTBEAT_INTERVAL = 60000;

const SipConnector = () => {
  const dispatch = useDispatch();
  const uaRef = useRef(null);
  const sessionRef = useRef(null);
  const socketRef = useRef(null);
  const remoteAudioRef = useRef(null);
  const heartbeatTimerRef = useRef(null);
  const isConnectedRef = useRef(false);

  const [transcriptions, setTranscriptions] = useState([]);

  const { callLogId } = useSelector((store) => ({
    callLogId: store.customCall.callLogId,
  }));

  console.log(callLogId, "callLogId sipconnector");

  const setupSocketConnection = useCallback(() => {
    socketRef.current = io(SOCKET_URL, {
      transports: ["websocket"],
      upgrade: false,
    });

    socketRef.current.on("transcription_update", (data) => {
      setTranscriptions((prevTranscriptions) => [
        ...prevTranscriptions,
        data.transcription,
      ]);
      console.log("Transcription:", data.transcription);
    });

    socketRef.current.on("connect", () => {
      console.log("Socket.IO connected");
      socketRef.current.emit("toggle_transcription", { action: "start" });
    });

    socketRef.current.on("disconnect", () => {
      console.log("Socket.IO disconnected");
    });

    socketRef.current.on("error", (error) => {
      console.error("Socket.IO error:", error);
    });
  }, []);

  const setupSIPConnection = useCallback(() => {
    const sipSocket = new JsSIP.WebSocketInterface(SIP_WS_URL);
    const configuration = {
      sockets: [sipSocket],
      uri: SIP_URI,
      password: SIP_PASSWORD,
      register: true,
      register_expires: REGISTRATION_INTERVAL,
      connection_recovery_min_interval: 60,
      connection_recovery_max_interval: 60,
      use_preloaded_route: true,
      hack_ip_in_contact: true,
    };

    const ua = new JsSIP.UA(configuration);
    uaRef.current = ua;

    ua.on("connected", () => {
      console.log("WebSocket connected");
      isConnectedRef.current = true;
      startHeartbeat();
    });

    ua.on("disconnected", () => {
      console.log("WebSocket disconnected");
      isConnectedRef.current = false;
      stopHeartbeat();
    });

    ua.on("registered", () => {
      console.log("Registered with SIP server");
      dispatch(registerSIP(ua));
      dispatch(getSession(sessionRef));
    });

    ua.on("unregistered", () => {
      console.log("Unregistered from SIP server");
    });

    ua.on("registrationFailed", (event) => {
      console.error("Registration failed:", event);
    });

    ua.on("newRTCSession", handleNewRTCSession);

    ua.start();
  }, [dispatch]);

  const startHeartbeat = useCallback(() => {
    if (heartbeatTimerRef.current) return;

    heartbeatTimerRef.current = setInterval(() => {
      if (uaRef.current && socketRef.current) {
        if (!uaRef.current.isConnected() || !uaRef.current.isRegistered()) {
          console.log("Heartbeat detected connection issue");
        }

        if (!socketRef.current.connected) {
          console.log("Heartbeat detected Socket.IO disconnection");
        }
      }
    }, HEARTBEAT_INTERVAL);
  }, []);

  const stopHeartbeat = useCallback(() => {
    if (heartbeatTimerRef.current) {
      clearInterval(heartbeatTimerRef.current);
      heartbeatTimerRef.current = null;
    }
  }, []);

  const callLogIdRef = useRef(callLogId);

  useEffect(() => {
    callLogIdRef.current = callLogId;
  }, [callLogId]);

  const transcribeAudio = useCallback(() => {

    dispatch(stopCaptureVoice()).then(async (audioBlob) => {
      try {
        const formData = new FormData();
        formData.append("file", audioBlob, "recording.webm");
        formData.append("call_log_id", callLogIdRef.current); // Use ref to access latest value

        const audioUrl = await CrmService.uploadAudio(formData);
        dispatch(setTranscription(audioUrl.data?.generated_response));
      } catch (e) {
        console.error("Error uploading audio:", e);
      }
    });
  }, [dispatch]);


  // const transcribeAudio = () => {
  //   const logcallId = callLogId
  //     console.log(callLogId, "callLogId sipconnector3");

  //   dispatch(stopCaptureVoice()).then(async (audioBlob) => {
  //     try {
  //       const formData = new FormData();
  //       formData.append("file", audioBlob, "recording.webm");
  //       formData.append("call_log_id", 1742)

  //       const audioUrl = await CrmService.uploadAudio(formData);
  //       dispatch(setTranscription(audioUrl.data?.generated_response));
  //     } catch (e) {
  //       console.error("Error uploading audio:", e);
  //     }
  //   });
  // };

  const handleAudioStream = (stream) => {
    const audioContext = new AudioContext();
    const source = audioContext.createMediaStreamSource(stream);
    const analyser = audioContext.createAnalyser();
    const dataArray = new Uint8Array(analyser.fftSize);

    let silenceTimeout = null;
    const silenceDuration = 2000;
    let isCurrentlyRecording = false;

    source.connect(analyser);

    const startRecording = () => {
      if (!isCurrentlyRecording) {
        console.log("Recording started");
        dispatch(startCaptureVoice());
        isCurrentlyRecording = true;
      }
    };

    const stopRecording = () => {
      if (isCurrentlyRecording) {
        console.log("Recording stopped due to silence");
        transcribeAudio();
        isCurrentlyRecording = false;
      }
    };

    const detectVoice = () => {
      analyser.getByteTimeDomainData(dataArray);

      const isSpeaking = dataArray.some((value) => Math.abs(value - 128) > 10);

      if (isSpeaking) {
        if (silenceTimeout) {
          clearTimeout(silenceTimeout);
          silenceTimeout = null;
        }
        if (!isCurrentlyRecording) {
          startRecording();
        }
      } else {
        if (!silenceTimeout) {
          silenceTimeout = setTimeout(() => {
            stopRecording();
          }, silenceDuration);
        }
      }

      requestAnimationFrame(detectVoice);
    };

    detectVoice();
  };

  const handleNewRTCSession = useCallback(
    (data) => {
      const session = data.session;
      sessionRef.current = session;

      dispatch(setIncommingCall(session, "outbound"));

      if (session.direction === "incoming") {
        playRingtone();
        dispatch(setIncommingCall(session, "inbound"));
        session.on("peerconnection", (e) => {
          const peerConnection = e.peerconnection;

          peerConnection.addEventListener("track", (event) => {
            if (event.track.kind === "audio") {
              const stream = event.streams[0];
              if (remoteAudioRef.current) {
                remoteAudioRef.current.srcObject = stream;
                remoteAudioRef.current.play().catch((error) => {
                  console.error("Error playing audio:", error);
                });

                handleAudioStream(stream);

                dispatch(
                  getAudioRef({
                    audioRef: remoteAudioRef.current,
                  })
                );
              }
            }
          });
        });
      }

      const sessionEvents = {
        accepted: () => {
          console.log("Call accepted");
          window.isCallConnected = true;
        },
        ended: () => {
          console.log("Call ended");
          dispatch(updateCallLogs("Completed"));
          dispatch(declineCallRequest());
          window.isCallConnected = false;
        },
        failed: () => {
          console.log("Call failed");
          dispatch(updateCallLogs("Failed"));
          dispatch(declineCallRequest());
          window.isCallConnected = false;
        },

        hold: () => {
          console.log("Call on hold");
          window.isCallConnected = true;
        },
        unhold: () => {
          console.log("Call resumed");
          window.isCallConnected = true;
        },
        muted: () => {
          console.log("Call muted");
          window.isCallConnected = true;
        },
        unmuted: () => {
          console.log("Call unmuted");
          window.isCallConnected = true;
        },
      };

      Object.entries(sessionEvents).forEach(([event, handler]) => {
        session.on(event, handler);
      });

      session.connection.ontrack = (event) => {
        const audioRef = (remoteAudioRef.current.srcObject = event.streams[0]);

        handleAudioStream(audioRef);

        dispatch(getAudioRef(audioRef));
      };
    },

    [dispatch]
  );

  const playRingtone = () => {
    const ringtone = new Audio("/path/to/ringtone.mp3");
    ringtone.loop = true;
    ringtone.play().catch((error) => {
      console.error("Error playing ringtone:", error);
    });
  };

  useEffect(() => {
    setupSocketConnection();
    setupSIPConnection();

    // return () => {
    //   if (heartbeatTimerRef.current) {
    //     clearInterval(heartbeatTimerRef.current);
    //   }
    //   if (uaRef.current) {
    //     uaRef.current.stop();
    //   }
    //   if (socketRef.current) {
    //     socketRef.current.disconnect();
    //   }
    // };
  }, [setupSocketConnection, setupSIPConnection]);

  return <audio ref={remoteAudioRef} autoPlay />;
};

export default SipConnector;
