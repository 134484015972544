import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  IconButton,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { CloseOutlined, Send } from "@mui/icons-material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

const ChatBot = ({ transcription, handleClose }) => {
  const [messages, setMessages] = useState([
    { sender: "bot", text: "Hi! How can I help you today?" },
  ]);

  const handleSendMessage = () => {
    // bot simulation
    if (transcription?.trim()) {
      setMessages([...messages, { sender: "user", text: transcription }]);
      setTimeout(() => {
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: "bot", text: "Let me check that for you..." },
        ]);
      }, 1000);
    }
  };

  useEffect(() => {
    handleSendMessage();
  }, [transcription]);

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        position: "absolute",
        top: 0,
        right: "-80%",
        backgroundColor: "white",
        borderRadius: "8px",
        width: "220px",
        height: "400px",
        overflow: "hidden",
      }}
    >
      {/* Chat header */}
      <Box
        p={1}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <AutoAwesomeIcon />
          <Typography variant="h6">Assistant</Typography>
        </Box>
        <IconButton onClick={handleClose}>
          <CloseOutlined />
        </IconButton>
      </Box>

      {/* Chat messages area */}
      <List
        sx={{
          flexGrow: 1,
          overflowY: "auto",

          backgroundColor: "#f9f9f9",
        }}
      >
        {messages.map((message, index) => (
          <ListItem
            key={index}
            sx={{
              display: "flex",
              justifyContent:
                message.sender === "user" ? "flex-end" : "flex-start",
            }}
          >
            <Paper
              sx={{
                p: 1,
                maxWidth: "70%",
                backgroundColor:
                  message.sender === "user" ? "#e3f2fd" : "#f1f1f1",
                borderRadius: 2,
              }}
            >
              <ListItemText
                primary={message.text}
                primaryTypographyProps={{
                  variant: "body2",
                  color: "textPrimary",
                }}
              />
            </Paper>
          </ListItem>
        ))}
      </List>

      {/* Input box and send button */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          p: 1,
          borderTop: "1px solid #eee",
          backgroundColor: "#f7f7f7",
        }}
      >
        {/* <TextField
          variant="outlined"
          placeholder="Type a message..."
          size="small"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
          fullWidth
          sx={{ mr: 1 }}
        />
        <IconButton
          color="primary"
          onClick={handleSendMessage}
          sx={{ p: "10px" }}
        >
          <Send />
        </IconButton> */}
      </Box>
    </Paper>
  );
};

export default ChatBot;
