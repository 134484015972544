import { customCallConstant } from "../constants/customCallConstant";
import { CrmService } from "../services";
import { format } from "date-fns";

let callStartTime;
let callEndTime;

export const customCallActions = {
  registerSIP,
  makeCall,
  getAudioRef,
  setIncommingCall,
  acceptCallRequest,
  holdCallRequest,
  unHoldCallRequest,
  transferCallRequest,
  progressCall,
  declineCallRequest,
  endCallRequest,
  muteCallRequest,
  StoreCallLogs,
  updateCallLogs,
  unMuteCallRequest,
  startCaptureVoice,
  stopCaptureVoice,
  setTranscription,
  getSession,
};

function registerSIP(ua) {
  return { type: customCallConstant.REGISTER_SIP, payload: ua };
}

function getSession(session) {
  console.log(session, "session");
  return { type: customCallConstant.GET_SESSION, payload: session };
}

function getAudioRef(audioRef) {
  return { type: customCallConstant.GET_AUDIO_REF, payload: audioRef };
}

function makeCall(number) {
  return (dispatch, getState) => {
    const state = getState();
    const { ua } = state.customCall;

    const options = {
      mediaConstraints: { audio: true, video: false },
    };

    if (ua) {
      ua.call(`sip:${number}@pbx.articence.com`, options);
      dispatch({ type: customCallConstant.MAKE_CALL, payload: number });
    }
  };
}

function setIncommingCall(call, callType) {
  console.log(call);
  const callerNumber = call.remote_identity.uri.user;
  return {
    type: customCallConstant.SET_INCOMING_CALL,
    payload: { call, callerNumber, callType },
  };
}

function muteCallRequest() {
  return (dispatch, getState) => {
    const state = getState();
    const { incomingCall } = state.customCall;

    if (incomingCall) {
      incomingCall.mute({
        audio: true,
        video: false,
      });
      dispatch({ type: customCallConstant.MUTE_CALL });
    } else {
      console.error("No incoming call to mute");
    }
  };
}

function unMuteCallRequest() {
  return (dispatch, getState) => {
    const state = getState();
    const { incomingCall } = state.customCall;

    if (incomingCall) {
      incomingCall.unmute({
        audio: true,
        video: false,
      });
      dispatch({ type: customCallConstant.UNMUTE_CALL });
    } else {
      console.error("No incoming call to mute");
    }
  };
}

function acceptCallRequest() {
  return { type: customCallConstant.ACCEPT_CALL };
}

function holdCallRequest() {
  return (dispatch, getState) => {
    const state = getState();
    const incomingCall = state?.customCall?.incomingCall;

    if (!incomingCall) {
      console.error("No active call to hold.");
      return;
    }

    try {
      console.log("Attempting to hold the call...");
      incomingCall.hold({
        useUpdate: false,
      });
      dispatch({ type: customCallConstant.HOLD_CALL });
    } catch (error) {
      console.error("Error while holding the call:", error);
    }
  };
}

function unHoldCallRequest() {
  return (dispatch, getState) => {
    const state = getState();
    const incomingCall = state?.customCall?.incomingCall;

    if (!incomingCall) {
      console.error("No active call to unhold.");
      return;
    }

    try {
      console.log("Attempting to unhold the call...");
      incomingCall.unhold({
        useUpdate: false,
      });

      dispatch({ type: customCallConstant.UNHOLD_CALL });
    } catch (error) {
      console.error("Error while unholding the call:", error);
    }
  };
}

function transferCallRequest(target) {
  return (dispatch, getState) => {
    const state = getState();

    try {
      const { incomingCall } = state.customCall;
      if (incomingCall) {
        incomingCall.refer(target);
        dispatch({ type: customCallConstant.TRANSFER_CALL });
      }
    } catch {
      console.error("Error while transferring the call");
    }
  };
}

function endCallRequest() {
  return { type: customCallConstant.END_CALL };
}

function progressCall() {
  return { type: customCallConstant.PROGRESS_CALL };
}

function declineCallRequest() {
  return (dispatch, getState) => {
    const state = getState();

    try {
      const { incomingCall } = state.customCall;
      if (incomingCall) {
        incomingCall.terminate();
        dispatch({ type: customCallConstant.DECLINE_CALL });
      }
    } catch (e) {
      dispatch({ type: customCallConstant.DECLINE_CALL });
      console.error("Error while declining the call", e);
    }
  };
}

function startCaptureVoice() {
  console.log("Voice capture started");
  return async (dispatch, getState) => {
    const state = getState();
    const { session } = state.customCall;

    const sessionRef = session?.current?.connection;

    if (sessionRef) {
      const remoteStream = new MediaStream();

      sessionRef.getReceivers().forEach((receiver) => {
        if (receiver.track && receiver.track.kind === "audio") {
          remoteStream.addTrack(receiver.track);
        }
      });

      if (remoteStream.getTracks().length === 0) {
        console.warn("No audio tracks available for recording.");
        return;
      }

      try {
        const remoteMediaRecorder = new MediaRecorder(remoteStream, {
          mimeType: "audio/webm",
        });

        const audioChunks = [];
        remoteMediaRecorder.ondataavailable = (event) => {
          if (event.data && event.data.size > 0) {
            audioChunks.push(event.data);
          }
        };

        remoteMediaRecorder.start(1000); // Record chunks every 1 second

        // Save MediaRecorder and audioChunks to session
        session.current.mediaRecorder = remoteMediaRecorder;
        session.current.audioChunks = audioChunks;

        console.log("MediaRecorder started:", remoteMediaRecorder);

        dispatch({ type: customCallConstant.START_CAPTURE_VOICE });
      } catch (error) {
        console.error("Failed to start MediaRecorder:", error);
      }
    } else {
      console.error("No session connection available for capturing voice.");
    }
  };
}

function stopCaptureVoice() {
  console.log("Stopping voice capture");
  return async (dispatch, getState) => {
    const state = getState();
    const { session } = state.customCall;

    if (session?.current?.mediaRecorder) {
      const mediaRecorder = session.current.mediaRecorder;
      const audioChunks = session.current.audioChunks;

      if (mediaRecorder.state !== "inactive") {
        return new Promise((resolve, reject) => {
          mediaRecorder.onstop = () => {
            console.log("MediaRecorder stopped");

            if (audioChunks.length === 0) {
              console.error("No audio chunks recorded.");
              reject(new Error("No audio chunks recorded"));
              return;
            }

            // Combine audio chunks into a single Blob
            const audioBlob = new Blob(audioChunks, { type: "audio/webm" });

            // Clear MediaRecorder and chunks from session
            session.current.mediaRecorder = null;
            session.current.audioChunks = null;

            console.log("Audio Blob created:", audioBlob);
            resolve(audioBlob); // Return the Blob
          };

          // Stop the MediaRecorder
          mediaRecorder.stop();
          dispatch({ type: customCallConstant.STOP_CAPTURE_VOICE });
          console.log("Stopping MediaRecorder...");
        });
      } else {
        console.warn("MediaRecorder is not active.");
      }
    } else {
      console.error("MediaRecorder not found in session.");
    }
  };
}

function setTranscription(transcription) {
  return { type: customCallConstant.TRANSCRIPTION, payload: transcription };
}

function StoreCallLogs(customerId, callType, callStatus, firstPhone) {
  return async (dispatch, getState) => {
    callStartTime = format(new Date(), "yyyy-MM-dd HH:mm:ss");

    const payload = {
      phone_number: firstPhone,
      call_start_time: callStartTime,
      call_end_time: callStartTime,
      call_status: callStatus,
      call_duration: 900,
      recording: true,
      call_type: callType,
      customer_id: customerId,
    };

    try {
      const response = await CrmService.createCallLogs(payload);
      const callLogId = response.data.call_log_id;

      dispatch({
        type: customCallConstant.STORE_CALL_LOGS,
        payload: { callLogId, firstPhone },
      });
    } catch (e) {
      console.log(e);
    }
  };
}

function updateCallLogs(callStatus) {
  return async (dispatch, getState) => {
    const state = getState();

    const callLogId = state.customCall.callLogId;
    const firstPhone = state.customCall.firstPhone;

    callEndTime = format(new Date(), "yyyy-MM-dd HH:mm:ss");

    const duration = new Date(callEndTime) - new Date(callStartTime);
    // need in seconds
    const callDuration = duration / 1000;

    const payload = {
      phone_number: firstPhone,
      call_start_time: callStartTime,
      call_end_time: callEndTime,
      call_status: callStatus,
      call_duration: callDuration,
    };

    try {
      const response = await CrmService.updateCallLogs(payload, callLogId);
      console.log(response);
    } catch (e) {
      console.log(e);
    }
  };
}
