import React, { useEffect } from "react";
import "./App.less";
import { Router, Route, Switch, Redirect, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactGA from "react-ga4";
import { history } from "./helpers";
import { alertActions } from "./actions";
import { PrivateRoute } from "./components/PrivateRoute";

import { ContactUs } from "./pages";

import Login from "./voc/login/Login";
import SignUp from "./voc/Signup/Signup";
import TeamRegister from "./voc/teamRegister/TeamRegister";
import ForgetPassword from "./voc/forgetPassword/ForgetPassword";
import TokenVerify from "./voc/forgetPassword/TokenVerify";
import Profile from "./voc/Signup/Profile";
import Confirmation from "./voc/Signup/ConfirmPage";
import AddCustomer from "./voc/CRM/CustomerTable/AddCustomer";
import ViewNotes from "./voc/CRM/CustomerTable/ViewNotes";
import MuiTable from "./voc/Table/CommonCustomerTable";
import VoiceAgentsTable from "./voc/Table/VoiceAgentsTable"
import VoiceRulesTable from "./voc/Table/VoiceRulesTable"
//import QuestionsAnswers from "./voc/Signup/QuestionsAnswers";
//import Form from "./voc/Form/index";
//import Survey from "./voc/Survey/index";
//import Subscription from "./voc/Survey/Subscription";

//import Scratch from "./voc/Survey/Scratch";
//import ScratchForm from "./voc/Form/ScratchForm";
//import CreateSurvey from "./voc/Survey/CreateSurvey/CreateSurvey";
import MainDashboard from "./voc/Dashboards/MainDashboard";
//import NewCreateSurvey from "./voc/Survey/DesignSurvey/NewCreateSurvery";
// import usePageTracking from './components/usePageTracking';
//import SurveyAnswer from "./voc/Survey/SurveyAnswer";
//import ShareLink from "./voc/Survey/shareLink/ShareLink";
//import EmbedMode from "./voc/Survey/shareLink/embedMode/index";
//import Template from "./voc/Dashboards/Template";
//import UploadExternalSurvey from "./voc/UploadSurvey/ExternalSurvey";
//import UploadHistoricalSurvey from "./voc/UploadSurvey/HistoricalSurvey";
//import FormEmbedPage from "./voc/Form/Embed/index";
//import FormSubmit from "./voc/Form/FormSubmit";

import UserSetting from "./voc/UserSettings/UserSetting";
import CustomerTable from "./voc/CRM/CustomerTable/CustomerTable";
import ScheduledCallsTable from "./voc/CRM/CustomerTable/ScheduledCallsTable";
import EditCustomerDetails from "./voc/CRM/CustomerTable/EditCustomerDetails";
import MoreCustomerDetails from "./voc/CRM/CustomerTable/MoreCustomerDetails";
//import  {PaymentSuccess, PaymentFailed } from "./voc/Form/QuestionLayout/PaymentRoute";
//import GooglePayComponent from "./voc/Form/QuestionLayout/GooglePay";
import VideoCall from "./voc/CRM/CustomerTable/VideoCall";
import IncomingCallModal from "./components/Dialer/IncommingCallModal";
import VoiceActivityRecorder from "./components/Testing/VoiceCapture";
import VocCalendar from "./voc/CRM/Calendar/Calendar";
import CallCenterDashboard from "./components/CallCenter/CallDashboard";
import { fetchApolloUser } from "./services/apollo";

const loading = () => null;

function App() {
  const alert = useSelector((state) => state.alert);
  const dispatch = useDispatch();
  // const location = useLocation();

  useEffect(() => {
    history.listen((location, action) => {
      // clear alert on location change
      dispatch(alertActions.clear());
    });
    const TRACKING_ID = "G-XCGDN5X1FM";
    ReactGA.initialize(TRACKING_ID);
    ReactGA.send({ hitType: "pageview", page: "/" });
  }, []);

  return (
    <Router history={history}>
      <IncomingCallModal />
      <React.Suspense fallback={loading()}>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/signup" component={SignUp} />
          <Route path="/TeamRegister" component={TeamRegister} />
          <Route path="/forgetPassword" component={ForgetPassword} />
          <Route path="/resetpassword" component={TokenVerify} />
          <Route path="/profile" component={Profile} />
          {/* <Route path="/subscription" component={Subscription} /> */}
          <Route path="/confirmation" component={Confirmation} />
          {/* <Route path="/questions" component={QuestionsAnswers} /> */}
          <PrivateRoute exact path="/" component={MainDashboard} />
          {/* <Route path="/template" component={Template} /> */}
          <Route path="/confirmation" component={Confirmation} />
          {/* <Route exact path="/scratch" component={Scratch} /> */}
          {/* <Route exact path="/scratchForm" component={ScratchForm} />
          <Route path="/scratch/survey/:id" component={Survey} />
          <Route path="/scratch/form/:id" component={Form} />
          <Route path="/survey_ans/:id/:personal" component={SurveyAnswer} />
          <Route path="/survey_ans/:id" component={SurveyAnswer} />
          <Route path="/form_ans/:id/:personal" component={FormSubmit} />
          <Route path="/form_ans/:id" component={FormSubmit} /> */}
          {/* <Route path="/embed/survey_ans/:id/:personal" component={EmbedMode} /> */}
          {/* <Route
            path="/embed/form_ans/:id/:personal"
            component={FormEmbedPage}
          />
          <Route
            exact
            path="/upload/external"
            component={UploadExternalSurvey}
          />
          <Route
            exact
            path="/upload/historical"
            component={UploadHistoricalSurvey}
          />
          <Route path="/share-link" component={ShareLink} /> */}
          <Route path="/user-setting" component={UserSetting} />
          <Route path="/customerTable" component={CustomerTable} />
          <Route path="/add-customer" component={AddCustomer} />
          <Route path="/scheduled-calls" component={ScheduledCallsTable} />
          <Route path="/viewEdit/:id" component={EditCustomerDetails} />
          <Route path="/muiTable" component={MuiTable} />
          <Route path="/viewNotes/:id" component={ViewNotes} />
          <Route path="/viewAgents" component={VoiceAgentsTable} />
          <Route path="/viewRules" component={VoiceRulesTable} />
          <Route path="/calendar" component={VocCalendar} />
          <Route path="/test" component={CallCenterDashboard} />
          <Route
            path="/moreCustomerDetails/:id"
            component={MoreCustomerDetails}
          />
          <Route path="/videocall/:roomId" component={VideoCall} />
          {/* <Route path="/google-pay" component={GooglePayComponent} />
          <Route path="/payment-success" component={PaymentSuccess} />
          <Route path="/payment-failed" component={PaymentFailed} /> */}
          <Redirect from="*" to="/" />
        </Switch>
      </React.Suspense>
    </Router>
  );
}

export default App;
