import api from "./api";

// async function addAppointments(appointmentsData, Id) {
//   const result = await api.post(`/bookAppointment`, appointmentsData);
//   return result;
// }
//getallgoogleappointment


async function addAppointments(appointmentsData, Id) {
  const user = JSON.parse(localStorage.getItem("user"));

  if (user?.social_type === "gmail") {
    const result = await api.post(`/bookappointmentsocialgoogle`, appointmentsData);
    return result;
  } else if (user?.social_type === null) {
    const result = await api.post(`/bookAppointment`, appointmentsData);
    return result;
  } else {
    throw new Error("Unsupported social_type or missing user data.");
  }
}

async function getUserExtension() {
  const result = await api.get(`/getUserExtension`);
  return result;
}

async function getAppointments() {
  const user = JSON.parse(localStorage.getItem("user"));

  if (user?.social_type === "gmail") {
    const result = await api.get(`/getallgoogleappointment`);
    return result;
  } else if (user?.social_type === null) {
    const result = await api.get(`/getallnonsocialappointment`);
    return result;
  } else {
    throw new Error("Unsupported social_type or missing user data.");
  }
}


async function getAppointmentsById(appointmentId) {
  const user = JSON.parse(localStorage.getItem("user"));

  if (user?.social_type === "gmail") {
    const result = await api.get(`/getgoogleappointmentbyeventid/${appointmentId}`);
    return result;
  } else if (user?.social_type === null) {
    const result = await api.get(`/getnonsocialappointmentid/${appointmentId}`);
    return result;
  } else {
    throw new Error("Unsupported social_type or missing user data.");
  }
} 

async function editAppointments(appointmentsData, appointmentId) {
  const user = JSON.parse(localStorage.getItem("user"));

  if (user?.social_type === "gmail") {
    const result = await api.put(`/updategoogleappointmentbyid/${appointmentId}`, appointmentsData);
    return result;
  } else if (user?.social_type === null) {
    const result = await api.put(`/update/nonsocial/appointmentbyid/${appointmentId}`, appointmentsData);
    return result;
  } else {
    throw new Error("Unsupported social_type or missing user data.");
  }
} 


async function deleteAppointments(appointmentId) {
  const user = JSON.parse(localStorage.getItem("user"));

  if (user?.social_type === "gmail") {
    const result = await api.delete(`/deletegoogleappointmentbyid/${appointmentId}`);
    return result;
  } else if (user?.social_type === null) {
    const result = await api.delete(`/deletenonsocialappointmentbyid/${appointmentId}`);
    return result;
  } else {
    throw new Error("Unsupported social_type or missing user data.");
  }
}


export const appointmentService = {
  addAppointments,
  getUserExtension,
  getAppointments,
  editAppointments,
  deleteAppointments,
  getAppointmentsById
}