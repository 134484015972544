import React, { useState, useEffect, useRef } from "react";
import JsSIP from "jssip";
import { Button, Modal } from "react-bootstrap";
import {
  Chip,
  Divider,
  Grid,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  Box,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import PhoneDisabledIcon from "@mui/icons-material/PhoneDisabled";
import DialpadIcon from "@mui/icons-material/Dialpad";
import SendIcon from "@mui/icons-material/Send";
import RefreshIcon from "@mui/icons-material/Refresh";
import Tooltip from "@mui/material/Tooltip";
import "./SimpleCall.css";
import { getLocalAccessToken } from "../../../helpers";
import io from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import { callAction } from "../../../actions/callActions";

const SimpleCall = ({ phoneNumber, customerId }) => {
  const { getAiResponse } = callAction;
  const [callStatus, setCallStatus] = useState("Disconnected");
  const [isCallConnected, setIsCallConnected] = useState(false);
  const [time, setTime] = useState(0);
  const [numberValue, setNumberValue] = useState(phoneNumber);
  const [open, setOpen] = useState(false);
  const [recording, setRecording] = useState(false);
  const [transcriptions, setTranscriptions] = useState([]);
  const [isVoiceCaptureEnabled, setIsVoiceCaptureEnabled] = useState(false);
  const [aiPrompt, setAiPrompt] = useState("");
  const [formdata, setFormData] = useState({
    purpose: "",
    prompt: "",
    rules: "",
  });
  const [aiResponse, setAiResponse] = useState("");
  const [loadingAiResponse, setLoadingAiResponse] = useState(false);
  // const { loading, data, error } = useSelector((state) => state.call);
  // const {data, error} = useSelector((state) => state.call);

  const remoteAudioRef = useRef(null);
  const uaRef = useRef(null);
  const sessionRef = useRef(null);
  const localMediaRecorderRef = useRef(null);
  const remoteMediaRecorderRef = useRef(null);
  const localChunksRef = useRef([]);
  const remoteChunksRef = useRef([]);
  const recordingIntervalRef = useRef(null);
  const localBlobReadyRef = useRef(false);
  const remoteBlobReadyRef = useRef(false);
  const localBlobRef = useRef(null);
  const remoteBlobRef = useRef(null);
  const timerRef = useRef(null);
  const canSendNextRecordingRef = useRef(true);
  const socketRef = useRef(null);
  // const isVoiceCaptureEnabled = true;
  const dispatch = useDispatch();

  useEffect(() => {
    socketRef.current = io("https://testvocapp-ws.articence.com", {
      transports: ["websocket"], // Force WebSocket
      upgrade: false, // Disable transport upgrade
    });
    socketRef.current.on("transcription_update", (data) => {
      // Append the transcription to the existing ones
      console.log("transcription:", data);
      setTranscriptions((prevTranscriptions) => [
        ...prevTranscriptions,
        data.transcription,
      ]);
    });
    
    socketRef.current.emit("toggle_transcription", { action: "start" });

    console.log(socketRef.current, "transcriptions");

    // SIP Configuration
    const socket = new JsSIP.WebSocketInterface(
      "wss://pbx.articence.com:8089/ws"
    );
    const configuration = {
      sockets: [socket],
      uri: "sip:1002@pbx.articence.com",
      password: "3dad3e8534cc407efc50efd127356075",
    };

    const ua = new JsSIP.UA(configuration);
    ua.start();
    uaRef.current = ua;

    ua.on("registered", () => {
      console.log("Registered with SIP server");
    });

    ua.on("newRTCSession", (data) => {
      const session = data.session;
      sessionRef.current = session;

      if (session.direction === "incoming") {
        session.answer({
          mediaConstraints: { audio: true, video: false },
        });
      }

      session.on("accepted", () => {
        console.log("Call accepted");
        setCallStatus("In Call");
        startTimer();
        // Check if recording is enabled
      });

      session.on("ended", () => {
        console.log("Call ended");
        setCallStatus("Disconnected");
        setIsVoiceCaptureEnabled(false);
        window.isVoiceCaptureEnabled = false;
        stopRecording();
        stopTimer();
        setTranscriptions([]);
      });

      session.on("failed", () => {
        console.log("Call failed");
        setCallStatus("Disconnected");
        setIsVoiceCaptureEnabled(false);
        window.isVoiceCaptureEnabled = false;
        stopRecording();
        stopTimer();
        setTranscriptions([]);
      });

      session.connection.ontrack = (event) => {
        remoteAudioRef.current.srcObject = event.streams[0];
      };
    });

    const handleKeyDown = (event) => {
      // Check if Space key is pressed
      console.log(
        "Event code:",
        event.code,
        "Is call connected:",
        window.isCallConnected
      );
      if (event.code === "Space" && window.isCallConnected) {
        const activeElement = document.activeElement;
        const isTextInputFocused =
          activeElement.tagName === "INPUT" ||
          activeElement.tagName === "TEXTAREA";

        if (!isTextInputFocused) {
          event.preventDefault();
          // triggerFunction();
          if (window.isVoiceCaptureEnabled) {
            handleStopCaptureVoice();
          } else {
            handleCaptureVoice();
          }
        }
      }
    };

    // Add event listener for keydown events
    window.isCallConnected = isCallConnected;
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      ua.stop();

      if (socketRef.current) {
        socketRef.current.disconnect();
      }

      // window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const handleCallButtonClick = () => {
    const ua = uaRef.current;

    const eventHandlers = {
      progress: () => {
        console.log("Call is in progress");
        setCallStatus("Calling");
        setIsCallConnected(true);
        window.isCallConnected = true;
      },
      failed: () => {
        console.log("Call failed");
        setCallStatus("Disconnected");
        setIsCallConnected(false);
        window.isCallConnected = false;
      },
      ended: () => {
        console.log("Call ended");
        setCallStatus("Disconnected");
        setIsCallConnected(false);
        window.isCallConnected = false;
      },
      confirmed: () => {
        console.log("Call confirmed");
        setCallStatus("In Call");
        setIsCallConnected(true);
        window.isCallConnected = true;
        console.log("Recording123:", recording);
        if (recording) {
          playPreRecordedMessage(sessionRef.current);
        }
      },
    };

    const options = {
      eventHandlers,
      mediaConstraints: { audio: true, video: false },
    };

    ua.call(`sip:${numberValue}@pbx.articence.com`, options);
  };

  const handleCallEnd = () => {
    const session = sessionRef.current;
    session.terminate();
  };

  const startRecording = (connection) => {
    const remoteStream = new MediaStream();
    connection.getReceivers().forEach((receiver) => {
      // if (receiver.track.kind === "audio") {
      // }
      remoteStream.addTrack(receiver.track);
    });

    // const remoteMediaRecorder = new MediaRecorder(remoteStream);
    const remoteMediaRecorder = new MediaRecorder(remoteStream, { mimeType: 'audio/webm' });

    console.log(remoteMediaRecorder, "recorder");
    remoteMediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        remoteChunksRef.current.push(event.data);
        socketRef.current.emit("audio_stream", event.data);
      }
    };

    remoteMediaRecorder.start(1000);
    remoteMediaRecorderRef.current = remoteMediaRecorder;
  };

  const stopRecording = () => {
    const remoteMediaRecorder = remoteMediaRecorderRef.current;

    if (remoteMediaRecorder) {
      remoteMediaRecorder.stop();

      remoteMediaRecorder.onstop = () => {
        const remoteBlob = new Blob(remoteChunksRef.current, {
          type: "audio/webm",
        });
        remoteChunksRef.current = [];
        remoteBlobReadyRef.current = true;
        remoteBlobRef.current = remoteBlob;

        console.log("Remote Blob:", remoteBlob);

        if (remoteBlobReadyRef.current) {
          downloadAudioFile();
          handleBlobs();
        }
      };

      // startRecording(sessionRef.current.connection);
    }
  };

  const handleBlobs = () => {
    canSendNextRecordingRef.current = false;
    socketRef.current.emit("audio_stream", remoteBlobRef.current);
    remoteBlobReadyRef.current = false;
  };

  const downloadAudioFile = () => {
    if (remoteBlobRef.current) {
      const url = URL.createObjectURL(remoteBlobRef.current);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = "recording.webm"; // Filename for the download
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url); // Clean up the URL object after download
    } else {
      console.log("No blob available to download");
    }
  };

  const playPreRecordedMessage = async (session) => {
    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
    const response = await fetch(
      `${process.env.PUBLIC_URL}/recordings/pre_recorded_message.wav`
    );
    const arrayBuffer = await response.arrayBuffer();
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

    const source = audioContext.createBufferSource();
    source.buffer = audioBuffer;
    const destination = audioContext.createMediaStreamDestination();
    source.connect(destination);
    source.start();

    const sender = session.connection
      .getSenders()
      .find((s) => s.track.kind === "audio");
    if (sender) {
      sender.replaceTrack(destination.stream.getAudioTracks()[0]);
    }
  };

  const sendToAPI = () => {
    if (transcriptions.length === 0) {
      console.log("No transcriptions to send");
      return;
    }
    const payload = {
      transcriptions: transcriptions,
      prompt: aiPrompt,
    };
    setLoadingAiResponse(true);

    fetch(`https://vocbetaapp.articence.com/voc_crm/customers/${customerId}/call/ai_response`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: "Bearer " + getLocalAccessToken(),
      },
      body: JSON.stringify(payload)
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setLoadingAiResponse(false);
      return response.json();
    })
    .then(data => {
      console.log('Data sent to API successfully');
      console.log('Response data:', data);
      const cleanedResponse = data.response.replace(/^"|"$/g, '');
      setAiResponse(cleanedResponse);
    })
    .catch(error => {
      console.error('Error sending files to API:', error);
    });
  };

  const startTimer = () => {
    const startTime = Date.now() - time;
    timerRef.current = setInterval(() => {
      setTime(Date.now() - startTime);
    }, 1000);
  };

  const stopTimer = () => {
    clearInterval(timerRef.current);
    setTime(0);
  };

  const formatTime = (time) => {
    const hours = String(Math.floor(time / 3600000)).padStart(2, "0");
    const minutes = String(Math.floor((time % 3600000) / 60000)).padStart(
      2,
      "0"
    );
    const seconds = String(Math.floor((time % 60000) / 1000)).padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  };

  const onNumberChange = (num) => {
    setNumberValue(num);
  };

  const handleCaptureVoice = () => {
    setIsVoiceCaptureEnabled(true);
    window.isVoiceCaptureEnabled = true;
    socketRef.current.emit("toggle_transcription", { action: "start" });
    startRecording(sessionRef.current.connection);
  };

  const handleStopCaptureVoice = () => {
    setIsVoiceCaptureEnabled(false);
    window.isVoiceCaptureEnabled = false;
    stopRecording();
    sendToAPI();
    dispatch(getAiResponse(customerId, { transcriptions }));
  };

  const handleFocus = () => {
    console.log("TextField is focused");
    console.log("Recording:", recording);
  };

  return (
    <div>
      <Grid item xs={12} justifyContent="flex-end">
        <div style={{ display: "flex", justifyContent: "end" }}></div>
      </Grid>
      <audio ref={remoteAudioRef} autoPlay id="remoteAudio" />
      <Grid container spacing={1}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <FormGroup row>
              <FormControlLabel
                control={<Typography>Call Status : {callStatus}</Typography>}
                label=""
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} md={6}>
            <div style={{ display: "flex", justifyContent: "end" }}>
              {isCallConnected ? (
                <Button variant="danger" onClick={handleCallEnd}>
                  <PhoneDisabledIcon sx={{ marginRight: 1 }} />
                  End Call
                </Button>
              ) : (
                <Button variant="primary" onClick={handleCallButtonClick}>
                  <CallIcon sx={{ marginRight: 1 }} />
                  Call
                </Button>
              )}
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Typography>Call Duration : {formatTime(time)}</Typography>
                }
                label=""
              />
            </FormGroup>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <>
                    <Typography sx={{ marginRight: 2 }}>
                      Phone Number:{" "}
                    </Typography>
                    <TextField
                      variant="outlined"
                      placeholder="Enter phone number"
                      value={numberValue}
                      onChange={(e) => onNumberChange(e.target.value)}
                      onFocus={handleFocus}
                      sx={{ marginBottom: 2 }}
                    />
                  </>
                }
                label=""
              />
            </FormGroup>
          </Grid>
        </Grid>

        <Grid item xs={12} pb={2}>
          <Divider>
            <Chip label="Setup" />
          </Divider>
        </Grid>

        <Grid container md={12} spacing={0.5} pb={2}>
          <Grid item md={6}>
            <Button
              variant="primary"
              onClick={handleCaptureVoice}
              disabled={!isCallConnected || isVoiceCaptureEnabled}
            >
              Capture Voice
            </Button>
          </Grid>
          <Grid item md={6}>
            <Button
              variant="primary"
              onClick={handleStopCaptureVoice}
              disabled={!isCallConnected || !isVoiceCaptureEnabled}
            >
              Stop Voice Capture
            </Button>
          </Grid>
        </Grid>

        <Grid container md={12} spacing={0.5} pb={2}>
          <Grid item md={12}>
            <TextField
              id="outlined-basic"
              label="Why are we calling"
              name="prompt"
              value={aiPrompt}
              onChange={(e) => setAiPrompt(e.target.value)}
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container md={12} spacing={0.5} pb={2}>
          <Grid item md={12}>
            <TextField
              id="outlined-basic"
              label="Caller Response Prompt"
              name="ai_response"
              value={aiResponse}
              variant="outlined"
              fullWidth
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    {loadingAiResponse && <CircularProgress size={20} />}
                  </InputAdornment>
                ),
              }}
              multiline
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  value={recording}
                  onChange={(e) => setRecording(e.target.checked)}
                />
              }
              label="Record Call"
            />
          </FormGroup>
        </Grid>
      </Grid>
      {/* <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  // value={recording}
                  // onChange={(e) => setRecording(e.target.checked)}
                />
              }
              label="Record Call Transcription"
            />
          </FormGroup>
        </Grid>
      </Grid> */}
    </div>
  );
};

export default SimpleCall;
