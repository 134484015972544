import React, { useEffect } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Avatar,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Chip,
  Card,
  CardContent,
  CardHeader,
} from "@mui/material";
import {
  Call,
  Person,
  Email,
  AccessTime,
  PhoneCallback,
  PhoneMissed,
  Group,
  GroupAdd,
  Warning,
  CheckCircle,
  Brightness1 as StatusDot,
} from "@mui/icons-material";

import {
  LineChart,
  Line,
  PieChart,
  Pie,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { CrmService } from "../../services";
import { getBusinessStatus } from "../../helpers/getBizHours";

const CallCenterDashboard = () => {
  const [customerDetails, setCustomerDetails] = React.useState([]);

  // Mock data
  const customerStats = {
    total: 16,
    active: 12,
    inactive: 4,
  };

  useEffect(() => {
    (async () => {
      const data = await CrmService.getAllCustomers();
      setCustomerDetails(data?.data?.customers);
    })();
  }, []);

  // Assuming `customerDetails` contains the customer information
  const agentStatusData = customerDetails.reduce((acc, customer) => {
    const businessStatus = getBusinessStatus(customer.timezone).status;

    // Map statuses to their display names and colors
    const statusMapping = {
      within: { name: "Available", color: "#4CAF50" },
      lunch: { name: "Lunch Break", color: "#FFC107" },
      outside: { name: "Away", color: "#F44336" },
    };

    const status = statusMapping[businessStatus];

    if (status) {
      const existingStatus = acc.find((item) => item.name === status.name);

      if (existingStatus) {
        existingStatus.value++;
      } else {
        acc.push({ name: status.name, value: 1, color: status.color });
      }
    }

    return acc;
  }, []);

  // total number of active customers
  const activeCustomers = customerDetails.filter((customer) => {
    const businessStatus = getBusinessStatus(customer.timezone).status;
    return businessStatus === "within";
  }).length;

  // active customer ratio
  const activeCustomerRatio = (
    (activeCustomers / customerStats.total) *
    100
  ).toFixed(2);

  console.log(agentStatusData);

  const callMetricsData = [
    { hour: "9AM", calls: 5, missedCalls: 5 },
    { hour: "10AM", calls: 6, missedCalls: 8 },
    { hour: "11AM", calls: 7, missedCalls: 12 },
    { hour: "12PM", calls: 4, missedCalls: 15 },
    { hour: "1PM", calls: 3, missedCalls: 10 },
    { hour: "2PM", calls: 6, missedCalls: 7 },
    { hour: "3PM", calls: 8, missedCalls: 2 },
    { hour: "4PM", calls: 1, missedCalls: 1 },
    { hour: "5PM", calls: 2, missedCalls: 0 },
  ];

  const missedCalls = [
    {
      name: "Sarah Johnson",
      time: "10 mins ago",
      priority: "High",
      number: "+1 234-567-8901",
    },
    {
      name: "Mike Thompson",
      time: "25 mins ago",
      priority: "Medium",
      number: "+1 234-567-8902",
    },
    {
      name: "Emily Davis",
      time: "45 mins ago",
      priority: "High",
      number: "+1 234-567-8903",
    },
  ];

  const emailQueue = [
    { name: "Technical Support", count: 23, priority: "High" },
    { name: "Billing Inquiries", count: 15, priority: "Medium" },
    { name: "General Questions", count: 31, priority: "Low" },
  ];

  const getPriorityColor = (priority) => {
    switch (priority) {
      case "High":
        return "error";
      case "Medium":
        return "warning";
      case "Low":
        return "success";
      default:
        return "default";
    }
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3, bgcolor: "background.default" }}>
      {/* Top Stats Cards */}
      <Grid container spacing={3} sx={{ mb: 3 }}>
        {[
          {
            title: "Total Customers",
            value: customerStats.total,
            icon: <Group />,
            color: "#2196F3",
            subtext: "+2.5% from last week",
          },
          {
            title: "Active Customers",
            value: activeCustomers,
            icon: <GroupAdd />,
            color: "#4CAF50",
            subtext: `${activeCustomerRatio}% active rate`,
          },
          {
            title: "Missed Calls Today",
            value: "24",
            icon: <PhoneMissed />,
            color: "#F44336",
            subtext: "+5 in last hour",
          },
          {
            title: "Avg Response Time",
            value: "2m 45s",
            icon: <AccessTime />,
            color: "#9C27B0",
            subtext: "-30s from yesterday",
          },
        ].map((item, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Typography color="textSecondary" variant="body2">
                    {item.title}
                  </Typography>
                  <Typography variant="h4" sx={{ my: 1 }}>
                    {item.value}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {item.subtext}
                  </Typography>
                </Box>
                <Avatar sx={{ bgcolor: item.color }}>{item.icon}</Avatar>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>

      {/* Charts Section */}
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12} md={8}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Call Volume Today
            </Typography>
            <Box sx={{ height: 400 }}>
              <ResponsiveContainer>
                <LineChart data={callMetricsData}>
                  <XAxis dataKey="hour" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="calls"
                    stroke="#2196F3"
                    strokeWidth={2}
                  />
                  <Line
                    type="monotone"
                    dataKey="missedCalls"
                    stroke="#F44336"
                    strokeWidth={2}
                  />
                </LineChart>
              </ResponsiveContainer>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Customer Status
            </Typography>
            <Box sx={{ height: 400 }}>
              <ResponsiveContainer>
                <PieChart>
                  <Pie
                    data={agentStatusData}
                    cx="50%"
                    cy="50%"
                    innerRadius={60}
                    outerRadius={100}
                    paddingAngle={5}
                    dataKey="value"
                  >
                    {agentStatusData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      {/* Bottom Section */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card elevation={3}>
            <CardHeader
              title="Recent Missed Calls"
              action={
                <IconButton>
                  <Warning color="error" />
                </IconButton>
              }
            />
            <Divider />
            <List>
              {missedCalls.map((call, index) => (
                <ListItem
                  key={index}
                  divider={index !== missedCalls.length - 1}
                >
                  <ListItemIcon>
                    <PhoneMissed color="error" />
                  </ListItemIcon>
                  <ListItemText
                    primary={call.name}
                    secondary={
                      <React.Fragment>
                        <Typography variant="body2" component="span">
                          {call.number}
                        </Typography>
                        <br />
                        <Typography variant="caption" color="textSecondary">
                          {call.time}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Chip
                      label={call.priority}
                      color={getPriorityColor(call.priority)}
                      size="small"
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card elevation={3}>
            <CardHeader
              title="Email Queue Status"
              action={
                <IconButton>
                  <Email color="primary" />
                </IconButton>
              }
            />
            <Divider />
            <CardContent>
              <Box sx={{ height: 300 }}>
                <ResponsiveContainer>
                  <BarChart data={emailQueue}>
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="count" fill="#2196F3">
                      {emailQueue.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={
                            entry.priority === "High"
                              ? "#F44336"
                              : entry.priority === "Medium"
                              ? "#FFC107"
                              : "#4CAF50"
                          }
                        />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CallCenterDashboard;
