import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, MenuItem, Button, Typography, Grid, Paper } from '@mui/material';
const formFields = [
  { label: 'Job Title', name: 'jobTitle', options: ['Managing Director', 'CEO', 'CTO'] },
  { label: 'Person Location', name: 'personLocation', options: ['USA', 'India', 'Germany'] },
  { label: 'Company Location', name: 'companyLocation', options: ['USA', 'India', 'Germany'] },
  { label: 'Company Name', name: 'companyName', options: ['microsoft.com', 'apple.com', 'google.com'] },
  { label: 'No of Employees', name: 'numEmployees', options: ['1-10', '11-50', '51-200'] },
  { label: 'No of Data', name: 'numData', options: ['1', '2', '3', '4', '5'] },
  { label: 'Per Page', name: 'perPage', options: ['5', '10', '15', '20'] },
];

const customerList = [
    { name: 'John Doe', jobTitle: 'Managing Director' },
    { name: 'Jane Smith', jobTitle: 'CEO' },
    { name: 'Chris Johnson', jobTitle: 'CTO' },
    { name: 'Sarah Lee', jobTitle: 'Managing Director' },
    { name: 'David Brown', jobTitle: 'CEO' },
    { name: 'Linda Wilson', jobTitle: 'CTO' },
    { name: 'Michael Clark', jobTitle: 'Managing Director' },
    { name: 'Sophia Evans', jobTitle: 'CEO' },
    { name: 'James White', jobTitle: 'CTO' },
    { name: 'Patricia Harris', jobTitle: 'Managing Director' },
  ];

const ApolloCustomerModal = ({ show, onClose, onSubmit }) => {
  const [dropdownValues, setDropdownValues] = useState({
    jobTitle: '',
    personLocation: '',
    companyLocation: '',
    companyName: '',
    numEmployees: '',
    numData: '',
    perPage: '',
  });
  const [showCustomerPopup, setShowCustomerPopup] = useState(false);

  const handleDropdownChange = (e) => {
    const { name, value } = e.target;
    setDropdownValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    const payload = {
      person_titles: [dropdownValues.jobTitle],
      q_organization_domains: dropdownValues.companyName,
      person_locations: dropdownValues.personLocation,
      organization_locations: dropdownValues.companyLocation,
      organization_num_employees_ranges: [dropdownValues.numEmployees],
      page: parseInt(dropdownValues.numData, 10),
      per_page: parseInt(dropdownValues.perPage, 10),
    };
    //onSubmit(payload);
    onClose();
    setShowCustomerPopup(true);
  };

  return (
    <>
    <Dialog open={show} onClose={onClose} fullWidth>
      <DialogTitle>Apollo Customer Filter</DialogTitle>
      <DialogContent>
        {formFields.map(({ label, name, options }, index) => (
          <TextField
            key={index}
            select
            fullWidth
            margin="normal"
            label={label}
            name={name}
            value={dropdownValues[name] || ''}
            onChange={handleDropdownChange}
          >
            {options.map((option, idx) => (
              <MenuItem key={idx} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>

    <Dialog open={showCustomerPopup} onClose={() => setShowCustomerPopup(false)} fullWidth>
        <DialogTitle>Apollo Customer List from {dropdownValues.companyName}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {customerList.slice(0, 10).map((customer, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Paper
                  elevation={3}
                  sx={{
                    position: 'relative',
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="h6">{customer.name}</Typography>
                  <Typography variant="body2">{customer.jobTitle}</Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowCustomerPopup(false)} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
</>

  );
};

export default ApolloCustomerModal;
